<template>
    <!-- Attendance Modal -->
    <div class="modal custom-modal fade" id="attendance_info" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Attendance Info</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
						    <div class="card punch-status">
                                <div class="card-body">
                                    <h5 class="card-title">Timesheet <small class="text-muted">{{selecteddatedata.date}}
									</small>
                                    </h5>
                                    <div class="punch-det">
                                        <h6>Punch In at</h6>
                                        <p>{{selecteddatedata.firstpunch}}</p>
                                    </div>
                                    <div class="punch-info">
                                        <div class="punch-hours">
                                            <span>{{selecteddatedata.total_hours_format}} hrs</span>
                                        </div>
                                    </div>
                                    <div class="punch-det">
                                        <h6>Punch Out at</h6>
                                        <p>{{selecteddatedata.lastpunch}}</p>
                                    </div>
                                    <div class="statistics">
                                        <div class="row">
                                            <div class="col-md-6 col-6 text-center">
                                                <div class="stats-box">
                                                    <p>Break</p>
                                                    <h6>{{selecteddatedata.total_break_hours}} hrs</h6>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-6 text-center">
                                                <div class="stats-box">
                                                    <p>Overtime</p>
                                                    <h6 v-if="selecteddatedata.total_hours > 8">
													{{selecteddatedata.total_hours - 8}} hrs</h6>
													<h6 v-else>0 hrs</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
							<div class="card recent-activity">
                                <div class="card-body">
                                    <h5 class="card-title">Activity</h5>
                                    <ul class="res-activity-list">
										<li v-for="inneritem in selecteddatedata.punchingdetails" :key="inneritem.employee_attendances_details_id">
                                            <p class="mb-0" v-if="inneritem.employee_attendances_details_type == 0">Punch In at</p>
											<p class="mb-0" v-else>Punch Out at</p>
                                            <p class="res-activity-time">
                                                <i class="fa-regular fa-clock"></i>
                                               {{formattolocaltime(inneritem.employee_attendances_details_time)}}
                                            </p>
                                        </li>
                                       <!-- <li>
                                            <p class="mb-0">Punch In at</p>
                                            <p class="res-activity-time">
                                                <i class="fa-regular fa-clock"></i>
                                                10.00 AM.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="mb-0">Punch Out at</p>
                                            <p class="res-activity-time">
                                                <i class="fa-regular fa-clock"></i>
                                                11.00 AM.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="mb-0">Punch In at</p>
                                            <p class="res-activity-time">
                                                <i class="fa-regular fa-clock"></i>
                                                11.15 AM.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="mb-0">Punch Out at</p>
                                            <p class="res-activity-time">
                                                <i class="fa-regular fa-clock"></i>
                                                1.30 PM.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="mb-0">Punch In at</p>
                                            <p class="res-activity-time">
                                                <i class="fa-regular fa-clock"></i>
                                                2.00 PM.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="mb-0">Punch Out at</p>
                                            <p class="res-activity-time">
                                                <i class="fa-regular fa-clock"></i>
                                                7.30 PM.
                                            </p>
                                        </li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Attendance Modal -->
	
	<div class="modal custom-modal fade" id="attendance_info2" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Leave Info</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
						    <div class="card punch-status">
                                <div class="card-body">
                                    <h5 class="card-title">Timesheet <small class="text-muted">{{selecteddatedata.date}}
									</small>
                                    </h5>
                                    <div class="punch-det">
                                        <h6>Reason of Leave</h6>
                                        <p>{{selecteddatedata.leave_type}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
	
	
	<div class="modal custom-modal fade" id="attendance_info3" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Leave Info</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
						    <div class="card punch-status">
                                <div class="card-body">
                                    <h5 class="card-title">Timesheet <small class="text-muted">{{selecteddatedata.date}}
									</small>
                                    </h5>
                                    <div class="punch-det">
                                        <h6>Reason of Leave</h6>
                                        <p v-if="selecteddatedata.holidaydetails && selecteddatedata.holidaydetails.name">{{selecteddatedata.holidaydetails.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	
</template>


<script>
import { ref } from "vue";
import moment from 'moment';
export default {
  props: {
    selecteddatedata: [Array, Object]
  },  
  data() {
    return {
    };
  },
  methods: {
	formattolocaltime(date){
		var convertedDate  = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: 'Asia/Calcutta'}));
		
		var formatdate = new Date((new Date(convertedDate)).toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}));
		
		return formatdate.getFullYear()+'-'+formatdate.getMonth()+'-'+formatdate.getDate()+' '+formatdate.getHours()+':'+formatdate.getMinutes()+':'+formatdate.getSeconds();
	}
  },
};
</script>
