<template>
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-4 d-flex">
      <div class="card flex-fill dash-statistics">
        <div class="card-body">
          <h5 class="card-title">Employee Statistics</h5>
          <div class="stats-list">
            <!-- Today Leave -->
            <div class="stats-info">
              <p>
                Today Leave
                <strong
                  >{{ todayLeaveCount }}
                  <small>/ {{ totalEmployees }}</small></strong
                >
              </p>
              <div class="progress">
                <div
                  :class="['progress-bar', 'bg-primary']"
                  :style="{ width: todayLeavePercentage + '%' }"
                  role="progressbar"
                  :aria-valuenow="todayLeavePercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <!-- Today Absent -->
            <div class="stats-info">
              <p>
                Today Absent
                <strong
                  >{{ todayAbsentCount }}
                  <small>/ {{ totalEmployees }}</small></strong
                >
              </p>
              <div class="progress">
                <div
                  :class="['progress-bar', 'bg-danger']"
                  :style="{ width: todayAbsentPercentage + '%' }"
                  role="progressbar"
                  :aria-valuenow="todayAbsentPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <!-- Pending Approvals -->
            <div class="stats-info">
              <p>
                Pending Approvals
                <strong>{{ approvalPending }}</strong>
              </p>
              <div class="progress">
                <div
                  :class="['progress-bar', 'bg-warning']"
                  :style="{ width: pendingApprovalPercentage + '%' }"
                  role="progressbar"
                  :aria-valuenow="pendingApprovalPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Task Statics -->
    <div class="col-md-12 col-lg-6 col-xl-4 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <h4 class="card-title">Task Statistics</h4>
          <div class="statistics">
            <div class="row">
              <div class="col-md-6 col-6 text-center">
                <div class="stats-box mb-4">
                  <p>Total Tasks</p>
                  <h3>{{ taskData.total_task }}</h3>
                </div>
              </div>
              <div class="col-md-6 col-6 text-center">
                <div class="stats-box mb-4">
                  <p>Overdue Tasks</p>
                  <h3>{{ taskData.overdue_task }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="progress mb-4">
            <div
              :class="['progress-bar', 'bg-purple']"
              :style="{ width: getPercentage('completed_task') + '%' }"
              role="progressbar"
              :aria-valuenow="getPercentage('completed_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ getPercentage("completed_task") }}%
            </div>
            <div
              :class="['progress-bar', 'bg-warning']"
              :style="{ width: getPercentage('inprogress_task') + '%' }"
              role="progressbar"
              :aria-valuenow="getPercentage('inprogress_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ getPercentage("inprogress_task") }}%
            </div>
            <div
              :class="['progress-bar', 'bg-success']"
              :style="{ width: getPercentage('onhold_task') + '%' }"
              role="progressbar"
              :aria-valuenow="getPercentage('onhold_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ getPercentage("onhold_task") }}%
            </div>
            <div
              :class="['progress-bar', 'bg-danger']"
              :style="{ width: getPercentage('pending_task') + '%' }"
              role="progressbar"
              :aria-valuenow="getPercentage('pending_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ getPercentage("pending_task") }}%
            </div>
            <!-- <div
              :class="['progress-bar', 'bg-secondary']"
              :style="{ width: getPercentage('overdue_task') + '%' }"
              role="progressbar"
              :aria-valuenow="getPercentage('overdue_task')"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ getPercentage("overdue_task") }}%
            </div> -->
          </div>

          <div>
            <p>
              <i
                :class="['fa-regular', 'fa-circle-dot text-purple', 'me-2']"
              ></i>
              Completed Tasks
              <span class="float-end">{{ taskData.completed_task }}</span>
            </p>
            <p>
              <i
                :class="['fa-regular', 'fa-circle-dot text-warning', 'me-2']"
              ></i>
              Inprogress Tasks
              <span class="float-end">{{ taskData?.inprogress_task }}</span>
            </p>
            <p>
              <i
                :class="['fa-regular', 'fa-circle-dot text-success', 'me-2']"
              ></i>
              On Hold Tasks
              <span class="float-end">{{ taskData.onhold_task }}</span>
            </p>
            <p>
              <i
                :class="['fa-regular', 'fa-circle-dot text-danger', 'me-2']"
              ></i>
              Pending Tasks
              <span class="float-end">{{ taskData.pending_task }}</span>
            </p>
            <!-- <p>
              <i :class="['fa-regular', 'fa-circle-dot text-info', 'me-2']"></i>
              Review Tasks
              <span class="float-end">{{ taskData.review_task }}</span>
            </p> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Shift Statics -->
    <div class="col-md-12 col-lg-12 col-xl-4 d-flex">
      <div class="card flex-fill dash-statistics">
        <div class="card-body">
          <h5 class="card-title">Shift Statistics</h5>
          <div class="stats-list">
            <div
              class="stats-info"
              v-for="(shift, index) in shifts"
              :key="index"
            >
              <p>
                {{ shift.name }}
                <strong
                  >{{ shift.count }}
                  <small>/ {{ shift.total_count }}</small></strong
                >
              </p>
              <div class="progress">
                <div
                  :class="['progress-bar', 'bg-primary']"
                  :style="{ width: getShiftPercentage(shift) + '%' }"
                  role="progressbar"
                  :aria-valuenow="getShiftPercentage(shift)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      totalEmployees: 0,
      todayLeaveCount: 0,
      todayAbsentCount: 0,
      approvalPending: 0,
      taskData: {
        total_task: 0,
        overdue_task: 0,
        completed_task: 0,
        inprogress_task: 0,
        onhold_task: 0,
        pending_task: 0,
        review_task: 0,
      },
      shifts: [],
    };
  },
  computed: {
    todayLeavePercentage() {
      return this.totalEmployees
        ? ((this.todayLeaveCount / this.totalEmployees) * 100).toFixed(2)
        : 0;
    },
    todayAbsentPercentage() {
      return this.totalEmployees
        ? ((this.todayAbsentCount / this.totalEmployees) * 100).toFixed(2)
        : 0;
    },
    pendingApprovalPercentage() {
      return this.approvalPending
        ? ((this.approvalPending / 2) * 100).toFixed(2)
        : 0;
    },
    shiftPercentage() {
      return this.shifts.total_count
        ? ((this.shifts.count / this.shifts.total_count) * 100).toFixed(2)
        : 0;
    },
  },
  methods: {
    fetchDashboardData() {
      axios
        .get("/dashboard/count")
        .then((response) => {
          const data = response.data.data;

          this.totalEmployees = data.total_employees || 0;
          this.todayLeaveCount = data.today_leaves || 0;
          this.todayAbsentCount = data.today_absentees || 0;
          this.approvalPending = data.pending || 0;

          if (data.task_count) {
            this.taskData.total_task = data.task_count.total_tasks || 0;
            this.taskData.overdue_task = data.task_count.overdue_tasks || 0;
            this.taskData.completed_task = data.task_count.completed_tasks || 0;
            this.taskData.inprogress_task =
              data.task_count.inprogress_tasks || 0;
            this.taskData.onhold_task = data.task_count.onhold_tasks || 0;
            this.taskData.pending_task = data.task_count.pending_tasks || 0;
            this.taskData.review_task = data.task_count.review_tasks || 0;
          }

          const shiftsData = data.shifts || [];
          this.shifts = shiftsData.map((shift) => ({
            name: shift.shift_name,
            total_count: shift.total_scheduled,
            count: shift.present_count,
          }));
        })
        .catch((error) => {
          console.error("Error fetching dashboard counts:", error);
        });
    },
    getShiftPercentage(shift) {
      return shift.total_count
        ? ((shift.count / shift.total_count) * 100).toFixed(2)
        : 0;
    },

    getPercentage(taskType) {
      const total = this.taskData.total_task || 0;
      return total ? ((this.taskData[taskType] / total) * 100).toFixed(2) : 0;
    },
  },
  mounted() {
    this.fetchDashboardData();
  },
};
</script>
