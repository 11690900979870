<template>
  <!-- Search Filter -->
  <Form @submit="searchEmployee"> 
  <div class="row filter-row">
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus" :class="{ focused: clientIdFocused }">
        <input
          type="text"
          class="form-control floating"
          v-model="searchform.clientId"
          @focus="clientIdFocused = true"
          @blur="clientIdFocused = searchform.clientId !== ''"
        />
        <label class="focus-label">Client ID</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus" :class="{ focused: clientNameFocused }">
        <input
          type="text"
          class="form-control floating"
          v-model="searchform.clientName"
          @focus="clientNameFocused = true"
          @blur="clientNameFocused = searchform.clientName !== ''"
        />
        <label class="focus-label">Client Name</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus select-focus">
       <!-- <vue-select
          :options="ClientsCompany"
          id="clientscompany"
          placeholder="Select Company"
		  v-model="searchform.companyid"
        />
        <label class="focus-label">Company</label>
		-->
		
		<a-select
            v-model:value="value"
            show-search
            placeholder="Select Company"
            style="width: 100%;"
            :options="optionsEmpDesignation"
            @change="handleChange"
          >
          </a-select>
		  
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="d-grid">
        <!--<a href="javascript:;" class="btn btn-success" @click="searchEmployee"> Search </a> -->
		<button class="btn btn-success submit-btn">Search</button>
      </div>
    </div>
	<div class="col-sm-6 col-md-2">
      <div class="d-grid">
	    <a href="javascript:;" class="btn btn-danger" @click="clearForm"> Clear </a>
	  </div>
    </div>
  </div>
  </Form>
  <!-- Search Filter -->
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import { Form } from "vee-validate";
export default {
  components: {
    Form,
  },
  props: {
    searchform: [Array, Object],
  },
  data() {
    return {
      select2: null,
      ClientsCompany: ["Select Company", "Global Technologies", "Delta Infotech"],
      clientName: "",
      clientId: "",
      clientIdFocused: false,
      clientNameFocused: false,
	  //searchform:{clientId:"",clientName:"",companyid:""},
	  optionsEmpDesignation:[],
    };
  },
  methods: {
	clearForm(){
	
	},
	handleChange(value){
		
		this.searchform.companyid = value;
		this.$emit('reload-data',this.searchform);
	},
	searchEmployee(){
		this.$emit('reload-data',this.searchform);
	},
	clearForm(){
		this.$emit('clear-data');
	}
  },
  mounted(){
	var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/company", [])
			.then((response) => {
				
				console.log(response.data.data.record);
				
				if(response.data.data.record){
				
					var designations = response.data.data.record;
					this.optionsEmpDesignation = designations.map(designation => {
					return {
						label: designation.company_name,
						value: designation.id
					};
				  });

				  this.optionsEmpDesignation.unshift({label: "All", value: ''})
		  
		  
				}
				
				this.Companies = response.data.data.record;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
  }
};
</script>
