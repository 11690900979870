<template>
  <div class="chat-window" v-if="task?.task_id" ref="formContainer">
    <div class="fixed-header">
      <div class="navbar">
        <div class="task-assign">
          <!-- <a class="task-complete-btn" id="task_complete" href="javascript:void(0);" @click="$emit('mark-complete',task)">
            <i class="material-icons">check</i> Mark as {{ task?.task_status == 'Completed' ? 'Pending' : 'Completed' }}
          </a> -->
          <label for="">Task Status</label>
          <select
            class="form-select"
            v-model="task.task_status"
            @change="updateTaskStatus"
          >
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Inprogress">Inprogress</option>
            <option value="Overdue">Overdue</option>
            <option value="Onhold">Onhold</option>
          </select>
        </div>
        <ul class="nav float-end custom-menu">
          <li class="dropdown dropdown-action">
            <a
              href="javascript:;"
              class="dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-end custom-dropdown-menu">
              <a class="dropdown-item" href="javascript:;" data-bs-toggle="modal"
              data-bs-target="#delete_task" @click="$emit('set-delete-task-id',task?.task_id)">Delete Task</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="chat-contents task-chat-contents">
      <div class="chat-content-wrap">
        <div class="chat-wrap-inner" >
          <div class="chat-box" ref="chatWrapInner">
            <div class="chats">
              <h4>{{ task?.task_title}}</h4>
              <div class="task-header">
                <div class="assignee-info">
                  <a
                    href="javascript:;"
                    data-bs-toggle="modal"
                    @click="$emit('reset-value')"
                    data-bs-target="#assignee"
                  >
                    <div class="avatar" v-if="assigned_emp?.id">
                      <img v-if="assigned_emp?.avatar != null"
                        :src="getEmployeeAvatar(assigned_emp?.avatar)"
                        @error="handleImageError(assigned_emp)"
                        alt="User Image"
                      />
                      <img v-else
                      :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                          alt="User Image"
                      />
                    </div>
                    <div class="assigned-info">
                      <div class="task-head-title">Assigned To</div>
                      <div class="task-assignee" v-if="assigned_emp?.id"> {{ assigned_emp?.first_name }} {{ assigned_emp?.last_name }} </div>
                      <div class="task-assignee" v-else> -</div>
                    </div>
                  </a>
                  <span class="remove-icon" v-if="assigned_emp?.id" @click="removeAssignee">
                    <i class="fa fa-close"></i>
                  </span>
                </div>
                <div class="task-due-date">
                  <a
                    href="javascript:;"
                  >
                    <div class="due-info">
                      <div class="task-head-title">Due Date</div>
                      <datepicker
                      v-model="dueDatedd"
                      placeholder="Choose Date"
                      class="form-control datetimepicker due-date"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                      <!-- <div class="due-date">Mar 26, 2023</div> -->
                    </div>
                  </a>
                  <!-- <span class="remove-icon">
                    <i class="fa fa-close"></i>
                  </span> -->
                </div>
              </div>
              <hr class="task-line" />
              <div class="task-desc">
                <div class="task-desc-icon">
                  <i class="material-icons">subject</i>
                </div>
                <div class="task-textarea">
                  <textarea class="form-control" @blur="updateTaskDescription" v-model="task_description" placeholder="Description"></textarea>
                </div>
              </div>

              <div class="chat chat-left mt-2" v-for="message in task_messages">
                  <div class="task-information" v-if="message?.project_task_log_id && message?.project_task_log_id != null">
                    <span class="task-info-line"
                      ><a class="task-user" href="javascript:;">{{ message?.user?.first_name }} {{ message?.user?.last_name }}</a>
                      <span class="task-info-subject">{{ message?.project_task_log_message }}</span></span
                    >
                    <div class="task-time">{{ getFullTime(message?.project_task_log_created_at) }}</div>
                  </div>
                  <div v-else>
                    <div class="chat-avatar">
                      <router-link :to="'profile/'+message?.user?.id" class="avatar">
                        <img v-if="message?.user?.avatar && message?.user?.avatar != null"
                        :src="getEmployeeAvatar(message?.user?.avatar)"
                        alt="User Image"
                        @error="handleUserImageError(message?.user)"
                        />
                        <img v-else
                        :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                            alt="User Image"
                        />
                      </router-link>
                    </div>
                    <div class="chat-body">
                      <div class="chat-bubble">
                        <div class="chat-content">
                          <span class="task-chat-user">{{ message?.user?.first_name }} {{ message?.user?.last_name }}</span>
                          <span class="chat-time">{{ getTime(message?.project_task_detail_created_time)}}</span>
                          <p>{{ message?.project_task_detail_message}}</p>
                          <div v-if="message?.attachment?.length">
                            <br>
                            <span class="file-attached"
                              >attached {{message?.attachment?.length}} file(s) <i class="fa-solid fa-paperclip"></i
                            ></span>
                            <span class="chat-time">{{ getFullTime(message?.project_task_detail_created_time) }}</span>
                            <ul class="attach-list">
                              <li v-for="files in message?.attachment" :class="{'img-file':imageTypes.includes(files.document_type)}">
                                <div v-if="imageTypes.includes(files.document_type)">
                                  <div class="attach-img-download">
                                    <a href="javascript:;" @click="viewDoc(files?.file_path)">{{files?.original_file_name}}</a>
                                  </div>
                                  <div class="task-attach-img">
                                    <img v-if="files?.file_path && files?.file_path != null"
                                        :src="getEmployeeAvatar(files?.file_path)"
                                        alt="User Image"
                                        @click="viewDoc(files?.file_path)"
                                        @error="handleattachmentImageError(files)"
                                        />
                                        <img v-else
                                        :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                                            alt="User Image"
                                        />
                                  </div>
                                </div>
                                <div v-else>
                                  <i class="fa fa-file me-1"></i>
                                  <a href="javascript:;" @click="viewDoc(files?.file_path)">{{ files?.original_file_name }}</a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <hr class="task-line" />





              <!-- <div class="task-information">
                <span class="task-info-line"
                  ><a class="task-user" href="javascript:;">Lesley Grauer</a>
                  <span class="task-info-subject">created task</span></span
                >
                <div class="task-time">Jan 20, 2023</div>
              </div>
              <div class="task-information">
                <span class="task-info-line"
                  ><a class="task-user" href="javascript:;">Lesley Grauer</a>
                  <span class="task-info-subject"
                    >added to Hospital Administration</span
                  ></span
                >
                <div class="task-time">Jan 20, 2023</div>
              </div>
              <div class="task-information">
                <span class="task-info-line"
                  ><a class="task-user" href="javascript:;">Lesley Grauer</a>
                  <span class="task-info-subject">assigned to John Doe</span></span
                >
                <div class="task-time">Jan 20, 2023</div>
              </div>
              <hr class="task-line" />
              <div class="task-information">
                <span class="task-info-line"
                  ><a class="task-user" href="javascript:;">John Doe</a>
                  <span class="task-info-subject">changed the due date to Sep 28</span>
                </span>
                <div class="task-time">9:09pm</div>
              </div>
              <div class="task-information">
                <span class="task-info-line"
                  ><a class="task-user" href="javascript:;">John Doe</a>
                  <span class="task-info-subject">assigned to you</span></span
                >
                <div class="task-time">9:10pm</div>
              </div>
              
              <div class="completed-task-msg">
                <span class="task-success"
                  ><a href="javascript:;">John Doe</a> completed this task.</span
                >
                <span class="task-time">Today at 9:27am</span>
              </div>
              <div class="chat chat-left">
                <div class="chat-avatar">
                  <router-link to="profile" class="avatar">
                    <img src="@/assets/img/profiles/avatar-02.jpg" alt="User Image" />
                  </router-link>
                </div>
                <div class="chat-body">
                  <div class="chat-bubble">
                    <div class="chat-content">
                      <span class="task-chat-user">John Doe</span>
                      <span class="file-attached"
                        >attached 3 files <i class="fa-solid fa-paperclip"></i
                      ></span>
                      <span class="chat-time">Feb 17, 2023 at 4:32am</span>
                      <ul class="attach-list">
                        <li>
                          <i class="fa fa-file me-1"></i>
                          <a href="javascript:;">project_document.avi</a>
                        </li>
                        <li>
                          <i class="fa fa-file me-1"></i>
                          <a href="javascript:;">video_conferencing.psd</a>
                        </li>
                        <li>
                          <i class="fa fa-file me-1"></i>
                          <a href="javascript:;">landing_page.psd</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat chat-left">
                <div class="chat-avatar">
                  <router-link to="profile" class="avatar">
                    <img src="@/assets/img/profiles/avatar-16.jpg" alt="User Image" />
                  </router-link>
                </div>
                <div class="chat-body">
                  <div class="chat-bubble">
                    <div class="chat-content">
                      <span class="task-chat-user">Jeffery Lalor</span>
                      <span class="file-attached"
                        >attached file <i class="fa-solid fa-paperclip"></i
                      ></span>
                      <span class="chat-time">Yesterday at 9:16pm</span>
                      <ul class="attach-list">
                        <li class="pdf-file">
                          <i class="fa-regular fa-file-pdf me-1"></i>
                          <a href="javascript:;">Document_2016.pdf</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat chat-left">
                <div class="chat-avatar">
                  <router-link to="profile" class="avatar">
                    <img src="@/assets/img/profiles/avatar-16.jpg" alt="User Image" />
                  </router-link>
                </div>
                <div class="chat-body">
                  <div class="chat-bubble">
                    <div class="chat-content">
                      <span class="task-chat-user">Jeffery Lalor</span>
                      <span class="file-attached"
                        >attached file <i class="fa-solid fa-paperclip"></i
                      ></span>
                      <span class="chat-time">Today at 12:42pm</span>
                      <ul class="attach-list">
                        <li class="img-file">
                          <div class="attach-img-download">
                            <a href="javascript:;">avatar-1.jpg</a>
                          </div>
                          <div class="task-attach-img">
                            <img src="@/assets/img/user.jpg" alt="User Image" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="task-information">
                <span class="task-info-line">
                  <a class="task-user" href="javascript:;">John Doe</a>
                  <span class="task-info-subject">marked task as incomplete</span>
                </span>
                <div class="task-time">1:16pm</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer">
      <div class="message-bar" v-if="selectedFiles?.length">
          <ul class="list-group mt-1 mb-1">
            <li v-for="(file, index) in selectedFiles" :key="index" class="list-group-item">
              <p class="mb-0">
                <i :class="file.icon"></i> {{ file.name }}
                <span class="text-danger float-end" @click="deleteFile(index)">
                  <i class="fa fa-trash"></i>
                </span>
              </p>
            </li>
          </ul>
      </div>
      <div class="message-bar">
        <div class="message-inner">
          <a class="link attach-icon" href="javascript:;" @click="$refs?.attachments.click()"
            ><img src="@/assets/img/attachment.png" alt="Attachment Icon"
          /></a>
          <input type="file" class="d-none" name="attechments[]" multiple ref="attachments" @change="handleFileChange" />
          <div class="message-area">
            <div class="input-group">
              <textarea class="form-control" v-model="message" placeholder="Type message..."></textarea>
              <button class="btn btn-primary" type="button" @click="sendMessage">
                <i class="fa-solid fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="project-members task-followers">
        <span class="followers-title">Followers</span>
        <a v-for="follower in task_followers"
          class="avatar"
          href="javascript:;"
          data-bs-toggle="tooltip"
          :title="follower?.first_name+' '+follower?.last_name"
        >
        <img v-if="follower?.avatar && follower?.avatar != null"
          :src="getEmployeeAvatar(follower?.avatar)"
          alt="User Image"
          @click="delete_follower = follower"
          @error="handleUserImageError(follower)"
          href="javascript:;" 
          data-bs-toggle="modal"
          data-bs-target="#delete_follower"
        />
        <img v-else
          :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
            alt="User Image"
        />
        </a>
        <a
          href="javascript:;"
          class="followers-add"
          data-bs-toggle="modal"
          data-bs-target="#task_followers"
          @click="$emit('reset-value')"
          ><i class="material-icons">add</i></a
        >
      </div>
    </div>
    <!-- Delete Follower Modal -->
    <div class="modal custom-modal fade" id="delete_follower" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Follower</h3>
              <p>Are you sure want to delete Follower {{ delete_follower?.first_name }}  {{ delete_follower?.last_name }}?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    @click="deleteFollower"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    ref="delete_follower_close"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Follower Modal -->
  </div>
  <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;">
    <p class="text-center">No task selected!</p>
  </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
export default {
  emits:['mark-complete','set-delete-task-id','reset-value'],
  data() {
    return {
      task:null,
      assigned_emp: null,
      dateFormat: 'dd-MM-yyyy',
      dueDate:null,
      dueDatedd:null,
      task_description:'',
      task_messages:[],
      message:'',
      selectedFiles:[],
      allSelectedFiles:[],
      imageTypes:['jpg', 'jpeg', 'png', 'gif','webp'],
      task_followers:[],
      delete_follower:null
    }
  },
  props: {
    task_id: {
      type: Number,
      required: false,
      default: () => null,
    },
    assignemp:{
      type:Object,
      require:false,
      default:() => null
    },
    setFollowers:{
      type:Array,
      require:false,
      default:() => []
    },
    fetchMessages__:{
      type:Boolean,
      require:false,
      default:() => false
    }
  },
  watch:{
    task_id: {
      handler(newId, oldId) {
          if(newId)
          {
            this.fetchData();
            this.fetchMessages();
          }
          else
          {
            this.task = null
            this.assigned_emp = null
          }
         
      },
      immediate: true
    },
    assignemp: {
      handler(newId, oldId) {
          if(newId?.id)
          {
              this.assigned_emp = newId
          }
      },
      immediate: true
    },
    dueDatedd:{
      handler(newId, oldId) {

        const newDateISOString = new Date(newId).toISOString() || null;
        const dueDateISOString = new Date(this.dueDate).toISOString() || null;

          if(newId != null && newDateISOString  !== dueDateISOString)
          {
            this.changeDueDate(newId)
          }
      },
      immediate: true

    },
    setFollowers:{
      handler(newId) {
        this.task_followers = newId || []
       
      },
      immediate: true

    },
    fetchMessages__:{
      handler(newId) {

        if(newId)
        {
          this.fetchMessages()
        }
        
      },
      immediate: true

    }
  },
  methods:{
    deleteFollower()
    {
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      axios.delete(`project/task/delete-follower/`+this?.delete_follower?.project_task_follower_id).then((response) => {
        this.delete_follower = null

        this.fetchMessages()

        this.$refs?.delete_follower_close?.click()
        notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

            this.task_followers = response?.data?.data || []

        }).catch(error=>{
          this.delete_follower = null
        this.$refs?.delete_follower_close?.click()

          if(error.response){

            var response = (error.response);
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }else{
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
        }
      })
    },
    viewDoc(file)
    {
      window.open(`${process.env.VUE_APP_IMAGE_BASE_URL}${file}`, '_blank');
    },
    getTime(date = null)
    {
      const date_ = new Date(date)
      return moment(date_).format('hh:mm A') || null;
    },
    getFullTime(date)
    {
      const date_ = new Date(date)
      return  moment(date_, 'MMM DD, YYYY [at] h:mma').format('MMM DD, YYYY [at] h:mma') || null;
    },
    handleattachmentImageError(item)
    {
      item.file_path = null
      item.file_path = `assets/img/profiles/avatar-02.jpg`
    },
    handleUserImageError(item) {
      item.avatar = null;
      item.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    deleteFile(index) {
      this.selectedFiles.splice(index, 1);
      this.allSelectedFiles.splice(index, 1);
    },
    handleFileChange(event) {
      const files = event.target.files;
      this.selectedFiles = [];
      this.allSelectedFiles = []
      for (let i = 0; i < files.length; i++) {
        this.allSelectedFiles[i] = files[i]; 
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileType = file.type.split('/')[0];
          const icon = fileType === 'image' ? 'fa fa-image' : 'fa fa-file';
          this.selectedFiles.push({ name: file.name, icon });
        };
        reader.readAsDataURL(file);
      }
    },
    updateTaskDescription()
    {
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const formData = new FormData()
      const task_description = this.task_description || null;
      formData.append('task_description',task_description)

      axios.post(`project/task/`+this.task_id+`/update-task-description`,formData).then((response) => {

        this.fetchMessages()

        notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

        }).catch(error=>{
        if(error.response){
            var response = (error.response);
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }else{
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
        }
      })
    },
    changeDueDate(date)
    {
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const formData = new FormData()
      const dueDate = moment(date).format('YYYY-MM-DD') || null;
      formData.append('task_due_date',dueDate)

      axios.post(`project/task/`+this.task_id+`/update-due-date`,formData).then((response) => {

        this.fetchMessages()

        this.dueDate = response?.data?.data?.task_due_date || null;

        notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

        }).catch(error=>{
        if(error.response){
            var response = (error.response);
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }else{
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
        }
        })


    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(assigned_emp) {
      assigned_emp.avatar = null;
      assigned_emp.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    fetchData(){ 

      let loader = this.$loading.show({
            container: this.$refs.formContainer,
            canCancel: false
        });

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      axios.get(`project/task/`+this.task_id).then((response) => {
        loader.hide();
        this.task = response?.data?.data || null;
        this.assigned_emp = response?.data?.data?.assign_to || null;
        this.dueDatedd = (this.task?.task_due_date != null ? new Date(this.task?.task_due_date) : null) || null;
        this.dueDate = this.task?.task_due_date || null;
        this.task_description = this.task?.task_description || null; 
        this.task_followers = this.task?.followers || []

      }).catch(error=>{
        loader.hide();
        if(error.response){
            var response = (error.response);
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            }else{
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
        }
      })
      
    },
    fetchMessages(){
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      axios.get(`project/task/`+this.task_id+'/get-messages').then((response) => {

        this.task_messages = response?.data?.data || null;

        const chatWrapInner = this.$refs?.chatWrapInner;
        chatWrapInner.scrollTop = chatWrapInner?.scrollHeight;
        
        }).catch(error=>{
        if(error.response){
            var response = (error.response);

            if(response?.data?.message != 'No messages found!')
            {
              notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              }else{
              notification.open({
                  message: 'Server Error',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                  },
              });
            }
        }
      })

    },
    
    updateTaskStatus() {
      this.$emit("mark-complete", this.task, this.task.task_status);
    },

    sendMessage(){
        if(this.message.trim() != '' || this.allSelectedFiles?.length)
        {
          var token = window.localStorage.getItem("token");
          axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          const project_id = this.$route.params.id || null
          const formData = new FormData()
          const message = this.message || null;

          formData.append('project_task_detail_message',message)
          formData.append('project_task_detail_task_id',this.task_id)
          formData.append('project_task_detail_project_id',project_id)

          if(this.allSelectedFiles?.length)
          {
            this.allSelectedFiles.forEach(element => {
              formData.append('attachment[]',element);
            });
          }
          
          var header = {headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          
          axios.post(`project/task/`+this.task_id+`/send-message`,formData,header).then((response) => {

            this.message = ''
            this.selectedFiles = []
            this.allSelectedFiles = []

            this.fetchMessages()

            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

            }).catch(error=>{
              this.fetchMessages()
            if(error.response){
                var response = (error.response);
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                }else{
                notification.open({
                    message: 'Server Error',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
            }
          })
        }
    },
    removeAssignee()
    {
      const employee_id = '';

      let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});

      if(!this.task_id)
      {
        loader.hide()
        notification.open({  
                message: 'Task not found!',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
            return;
      }

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      const formData = new FormData();

      formData.append('employee_id',employee_id);


      axios.post('/project/task/'+this.task_id+'/assign-employee',formData).then((response)=>{

        this.$refs?.assign_task_modal_close?.click()

        this.assigned_emp = null

        notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

        loader.hide()
      }).catch(error=>{
        loader.hide()
        if(error.response){
          var response = (error.response);
            
          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        
          this.errors = response?.data?.error
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
      })
    }
  }

}
</script>
<style>
.v3dp__datepicker .v3dp__input_wrapper .datetimepicke.due-date
{
  border:none !important
}
</style>
