<template>
  <!-- Add Shift Modal -->
  <div id="add_shift" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">  {{ editShift && editShift?.shift_id ? 'Edit' : 'Add'}} Shift</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeModalRefOne"
            @click="closeShiftModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Shift Name <span class="text-danger">*</span></label
                  >
                  <div class="input-group time">
                    <input @change="handleChangeEvent('shift_name')" :class="{'is-invalid':clientError.includes('shift_name')}" type="text" class="form-control" v-model="formShift.shift_name"  />
                  </div>
                  <span class="text-danger" v-if="formErrors && formErrors?.shift_name && formErrors?.shift_name != null">{{ formErrors?.shift_name[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Min Start Time <span class="text-danger">*</span></label
                  >
                  <div class="input-group time">
                    <a-time-picker
                    @change="handleChangeEvent('min_start_time')"
                      v-model:value="formShift.min_start_time"
                      class="form-control timepicker"
                      placeholder="Time"
                      :class="{'is-invalid':clientError.includes('min_start_time')}"
                      
                    /><span class="input-group-text"
                      ><i class="fa-regular fa-clock"></i
                    ></span>
                  <span class="text-danger" v-if="formErrors && formErrors?.min_start_time && formErrors?.min_start_time != null">{{ formErrors?.min_start_time[0]  }}</span>

                  </div>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Start Time <span class="text-danger">*</span></label
                  >
                  <div class="input-group time">
                    <a-time-picker
                    @change="handleChangeEvent('start_time')"
                      v-model:value="formShift.start_time"
                      class="form-control timepicker"
                      placeholder="Time"
                      :class="{'is-invalid':clientError.includes('start_time')}"
                      
                    /><span class="input-group-text"
                      ><i class="fa-regular fa-clock"></i
                    ></span>
                  <span class="text-danger" v-if="formErrors && formErrors?.start_time && formErrors?.start_time != null">{{ formErrors?.start_time[0]  }}</span>

                  </div>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Max Start Time <span class="text-danger">*</span></label
                  >
                  <div class="input-group time">
                    <a-time-picker
                    @change="handleChangeEvent('max_start_time')"
                      v-model:value="formShift.max_start_time"
                      class="form-control timepicker"
                      placeholder="Time"
                      :class="{'is-invalid':clientError.includes('max_start_time')}"
                     
                    /><span class="input-group-text"
                      ><i class="fa-regular fa-clock"></i
                    ></span>
                  <span class="text-danger" v-if="formErrors && formErrors?.max_start_time && formErrors?.max_start_time != null">{{ formErrors?.max_start_time[0]  }}</span>

                  </div>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Min End Time <span class="text-danger">*</span></label
                  >
                  <div class="input-group time">
                    <a-time-picker
                    @change="handleChangeEvent('min_end_time')"
                      v-model:value="formShift.min_end_time"
                      class="form-control timepicker"
                      placeholder="Time"
                      :class="{'is-invalid':clientError.includes('min_end_time')}"
                     
                    /><span class="input-group-text"
                      ><i class="fa-regular fa-clock"></i
                    ></span>
                  <span class="text-danger" v-if="formErrors && formErrors?.min_end_time && formErrors?.min_end_time != null">{{ formErrors?.min_end_time[0]  }}</span>

                  </div>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >End Time <span class="text-danger">*</span></label
                  >
                  <div class="input-group time">
                    <a-time-picker
                    @change="handleChangeEvent('end_time')"
                      v-model:value="formShift.end_time"
                      class="form-control timepicker"
                      placeholder="Time"
                      :class="{'is-invalid':clientError.includes('end_time')}"
                     
                    /><span class="input-group-text"
                      ><i class="fa-regular fa-clock"></i
                    ></span>
                  <span class="text-danger" v-if="formErrors && formErrors?.end_time && formErrors?.end_time != null">{{ formErrors?.end_time[0]  }}</span>

                  </div>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Max End Time <span class="text-danger">*</span></label
                  >
                  <div class="input-group time">
                    <a-time-picker
                    @change="handleChangeEvent('max_end_time')"
                      v-model:value="formShift.max_end_time"
                      class="form-control timepicker"
                      placeholder="Time"
                      :class="{'is-invalid':clientError.includes('max_end_time')}"
                     
                    /><span class="input-group-text"
                      ><i class="fa-regular fa-clock"></i
                    ></span>
                  <span class="text-danger" v-if="formErrors && formErrors?.max_end_time && formErrors?.max_end_time != null">{{ formErrors?.max_end_time[0]  }}</span>

                  </div>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Break Time (In Minutes) <span class="text-danger">*</span> </label>
                  <input type="number" @change="handleChangeEvent('break_time')" :class="{'is-invalid':clientError.includes('break_time')}" class="form-control" v-model="formShift.break_time" min="0"  />
                <span class="text-danger" v-if="formErrors && formErrors?.break_time && formErrors?.break_time != null">{{ formErrors?.break_time[0]  }}</span>

                </div>

              </div>
              <div class="col-sm-12">
                <div class="form-check" style="padding-left:0px">
                  <input
                    type="checkbox"
                    class="days recurring"
                    id="customCheck1"
                    true-value="1" false-value="false"
                    v-model="formShift.recurring_shifts"
                    :checked="formShift.recurring_shifts"
                    
                  />
                  <label class="form-check-label" for="customCheck1" style="margin-left:5px"
                    >Recurring Shift <span class="text-danger">*</span></label
                  >

                </div>
                <span class="text-danger" v-if="formErrors && formErrors?.recurring_shifts && formErrors?.recurring_shifts != null">{{ formErrors?.recurring_shifts[0]  }}</span>
              </div>
              <div class="col-sm-12">
                <div class="input-block mb-3">
                  <label class="col-form-label">Repeat Every <span class="text-danger">*</span></label>
                  <select @change="handleChangeEvent('repeat')" :class="{'is-invalid':clientError.includes('repeat')}" class="form-control" v-model="formShift.repeat">
                    <option v-for="rpt in Repeat" :key="rpt">{{ rpt }}</option>
                  </select>
                  <label class="col-form-label">Week(s) <span class="text-danger">*</span> </label>
                <span class="text-danger" v-if="formErrors && formErrors?.repeat && formErrors?.repeat != null"> {{ ' '+formErrors?.repeat[0]  }}</span>

                </div>
              </div>
			  <div class="col-sm-12" :class="{'is-invalid':clientError.includes('weekdays')}" v-if="formShift.recurring_shifts">
                <div class="input-block mb-3 wday-box">
                  <label class="checkbox-inline"
                    ><input
                      v-model="formShift.weekdays"
                      type="checkbox"
                      value="monday"
                      class="days recurring"
                      
                     
                    /><span class="checkmark ms-1">Monday</span></label
                  >

                  <label class="checkbox-inline ms-1"
                    ><input
                      v-model="formShift.weekdays"
                      type="checkbox"
                      value="tuesday"
                      class="days recurring"
                     
                    /><span class="checkmark ms-1">Tuesday</span></label
                  >

                  <label class="checkbox-inline ms-1"
                    ><input
                      v-model="formShift.weekdays"
                      type="checkbox"
                      value="wednesday"
                      class="days recurring"
                     
                    /><span class="checkmark ms-1">Wednesday</span></label
                  >

                  <label class="checkbox-inline ms-1"
                    ><input
                      v-model="formShift.weekdays"
                      type="checkbox"
                      value="thursday"
                      class="days recurring"
                     
                    /><span class="checkmark ms-1">Thursday</span></label
                  >

                  <label class="checkbox-inline ms-1"
                    ><input
                      v-model="formShift.weekdays"
                      type="checkbox"
                      value="friday"
                      class="days recurring"
                     
                    /><span class="checkmark ms-1">Friday</span></label
                  >

                  <label class="checkbox-inline ms-1"
                    ><input
                      v-model="formShift.weekdays"
                      type="checkbox"
                      value="saturday"
                      class="days recurring"
                    /><span class="checkmark ms-1">Saturday</span></label
                  >

                  <label class="checkbox-inline ms-1"
                    ><input
                      v-model="formShift.weekdays"
                      type="checkbox"
                      value="sunday"
                      class="days recurring"
                    /><span class="checkmark ms-1">Sunday</span></label
                  >
                </div>
              </div>
              <div class="col-sm-12">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >End On <span class="text-danger">*</span></label
                  >
                  <div class="cal-icon">
                    <datepicker
                      v-model="formShift.endon"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                      :class="{'is-invalid':clientError.includes('endon')}"
                      @change="handleChangeEvent('endon')"
                    />
                  </div>
                <span class="text-danger" v-if="formErrors && formErrors?.endon && formErrors?.endon != null">{{ formErrors?.endon[0]  }}</span>

                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-check" style="padding-left:0px">
                  <input
                    type="checkbox"
                    class="days recurring"
                    id="customCheck2"
                    value="1"
                    v-model="formShift.indefinite"
                    :checked="formShift.indefinite"
                  />
                  <label class="form-check-label" for="customCheck2" style="margin-left:5px"
                    >Indefinite </label
                  >

                </div>
                <span class="text-danger" v-if="formErrors && formErrors?.indefinite && formErrors?.indefinite != null">{{ formErrors?.indefinite[0]  }}</span>

              </div>

              <div class="col-md-12">
                <div class="input-block mb-3">
                  <label class="col-form-label">Add Tag </label>
                  <input @change="handleChangeEvent('tags')" :class="{'is-invalid':clientError.includes('tags')}" type="text" v-model="formShift.tags" class="form-control" />
                <span class="text-danger" v-if="formErrors && formErrors?.tags && formErrors?.tags != null">{{ formErrors?.tags[0]  }}</span>

                </div>
              </div>
              <div class="col-md-12">
                <div class="input-block mb-3">
                  <label class="col-form-label">Add Note </label>
                  <textarea @change="handleChangeEvent('note')" :class="{'is-invalid':clientError.includes('note')}" v-model="formShift.note" class="form-control"></textarea>
                  <span class="text-danger" v-if="formErrors && formErrors?.note && formErrors?.note != null">{{ formErrors?.note[0]  }}</span>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" :disable="disableButton" @click="createShift">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Shift Modal -->

 

  <!-- Delete Shift Modal -->
  <div class="modal custom-modal fade" id="delete_employee" role="dialog" >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Shift</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
                  @click="onDeleteShift"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                  ref="closeModalRef"
                  @click="closeShiftModal"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Employee Modal -->
</template>

<script>
import { ref} from "vue";
const currentDate = ref(new Date());
import dayjs from 'dayjs';
import { mapActions, mapGetters } from 'vuex';
import { notification } from "ant-design-vue";
export default {
  emits: ["delete-shift","reload-data"],
  props:{
    deleteId:[Number,String],
    closeModal:[Boolean],
    editShift:[Object,null]
  },
  data() {
    return {
      value: null,
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      value6: null,
      value7: null,
      value8: null,
      value9: null,
      value10: null,
      value11: null,
      value12: null,
      value13: null,
      value14: null,
      value15: null,
      value16: null,
      startdate: dayjs(currentDate).format("DD-MM-YYYY"),
      dateFormat: 'dd-MM-yyyy',
      Repeat: ["1", "2", "3", "4", "5", "6"],
      Department: [
        "Select",
        "Development",
        "Finance",
        "Finance and Management",
        "Hr & Finance",
        "ITech",
      ],
      Employee: [
        "Select",
        "Richard Miles",
        "John Smith",
        "Mike Litorus",
        "Wilmer Deluna",
      ],
      Shifts: [
        "Select",
        "10'o clock Shift",
        "10:30 shift",
        "Daily Shift",
        "New Shift",
      ],
      formShift:{
        id:null,
        shift_name:'',
        min_start_time:null,//dayjs('08:00:00', 'HH:mm:ss'),
        start_time:null,
        max_start_time:null,
        min_end_time:null,
        end_time:null,
        max_end_time:null,
        break_time:0,
        recurring_shifts:false,
        repeat:'',
        weekdays:[],
        endon:new Date(),
        indefinite:'',
        tags:'',
        note:''
      },
      formErrors:{},
      disableButton:false,
      clientError:[]
    };
  },
  methods:{
    ...mapActions(['manageShift','getDateFormate']),
    handleChangeEvent(target)
    {
      this.clientError = this.clientError.filter(item => item !== target);
    },
    getTmeFormate(dateTimeObject){

      var date = new Date(dateTimeObject?.$d)

      if(date && date != null)
      {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format time string
        const timeString = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        return timeString;
      }
      else
      {
        const timeString = null
        return timeString;
      }

    },  
    async createShift()
    {
	  console.log(this.formShift.recurring_shifts);
	   
        this.disableButton = true;
        var formData = {
          id:null,
          shift_name:'',
          min_start_time:null,
          start_time:null,
          max_start_time:null,
          min_end_time:null,
          end_time:null,
          max_end_time:null,
          break_time:0,
          recurring_shifts:false,
          repeat:null,
          weekdays:[],
          endon:null,
          indefinite:'',
          tags:'',
          note:'',
          shift_id:null
        }
        
        formData = { ...this.formShift };

        formData.endon = null;

          this.clientError = []
          formData.shift_name = this.formShift?.shift_name

          if(formData.shift_name == '')
          {
              this.clientError.push('shift_name')
          }

          if(!(this.formShift?.min_start_time == 'NaN:NaN:NaN' || !this.formShift?.min_start_time?.$d || this.formShift?.min_start_time == null))
          {
            formData.min_start_time = this.getTmeFormate(this.formShift?.min_start_time)
          }
          else
          {
            this.clientError.push('min_start_time')
            formData.min_start_time = null;
          }
          if(!(this.formShift?.start_time == 'NaN:NaN:NaN' || !this.formShift?.start_time?.$d || this.formShift?.start_time == null))
          {
            formData.start_time = this.getTmeFormate(this.formShift?.start_time)
          }
          else
          {
            this.clientError.push('start_time')
            formData.start_time = null;
          }
          if(!(this.formShift?.end_time == 'NaN:NaN:NaN' || !this.formShift?.end_time?.$d || this.formShift?.end_time == null))
          {
            formData.end_time = this.getTmeFormate(this.formShift?.end_time)
          }
          else
          {
            this.clientError.push('end_time')
            formData.end_time = null;
          }
          if(!(this.formShift?.max_start_time == 'NaN:NaN:NaN' || !this.formShift?.max_start_time?.$d || this.formShift?.max_start_time == null))
          {
            formData.max_start_time = this.getTmeFormate(this.formShift?.max_start_time)
          }
          else
          {
            this.clientError.push('max_start_time')
            formData.max_start_time = null;
          }
          if(!(this.formShift?.min_end_time == 'NaN:NaN:NaN' || !this.formShift?.min_end_time?.$d || this.formShift?.min_end_time == null))
          {
            formData.min_end_time = this.getTmeFormate(this.formShift?.min_end_time)
          }
          else
          {
            this.clientError.push('min_end_time')
            formData.min_end_time = null;
          }
          if(!(this.formShift?.max_end_time == 'NaN:NaN:NaN' || !this.formShift?.max_end_time?.$d || this.formShift?.max_end_time == null))
          {
            formData.max_end_time = this.getTmeFormate(this.formShift?.max_end_time)
          }
          else
          {
            this.clientError.push('max_end_time')
            formData.max_end_time = null;
          }

          if (this.formShift?.endon) {
            const endonDate =  new Date(this.formShift?.endon);
            if (!isNaN(endonDate.getTime())) {
              formData.endon = endonDate.getFullYear()+'-'+endonDate.getMonth()+'-'+endonDate.getDate();
            }
            else
            {
              this.clientError.push('endon')
            }
          }
          else
          {
            this.clientError.push('endon')
            formData.endon = null
          }

          if(this.formShift?.break_time != '' && this.formShift?.break_time > 0)
          {
            formData.break_time = this.formShift?.break_time
          }
          else
          {
            this.clientError.push('break_time')
          }

          if(this.formShift?.recurring_shifts)
          {
            formData.recurring_shifts = this.formShift?.recurring_shifts
          }
          else
          {
            formData.recurring_shifts = 0;
            
          }

          if(this.formShift?.repeat != '')
          {
            formData.repeat = this.formShift?.repeat
          }
          else
          {
            this.clientError.push('repeat')
          }

          if(this.formShift?.weekdays != '')
          {
            formData.weekdays = this.formShift?.weekdays.join(',')
          }
          else
          {
            this.clientError.push('weekdays')
          }

          if(this.formShift?.indefinite != '')
          {
            formData.indefinite = this.formShift?.indefinite
          }
          else
          {
            formData.indefinite = 0;
          }
          
          // if(this.formShift?.tags != '')
          // {
          //   formData.tags = this.formShift?.tags
          // }
          // else
          // {
          //   this.clientError.push('tags')
          // }
          
          // if(this.formShift?.note != '')
          // {
          //   formData.note = this.formShift?.note
          // }
          // else
          // {
          //   this.clientError.push('note')
          // }

          if(this.formShift?.shift_id != null )
          {
            formData.shift_id = this.formShift?.shift_id 
          }
		  
		   

          if(this.clientError?.length)
          {
            this.disableButton = false;
			
			if(this.clientError.includes('weekdays')){
				
				 notification.open({
				 message: "Error in selection of Recurring Shift's weekday",
				 placement: "topRight",
				 duration: process.env.VUE_APP_NOTIFICATION_DURATION,
				 style: {
				   background: process.env.VUE_APP_WARNING_COLOR,
				 },
			   });
		   }
		   
          }
          else
          {
			
			 let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
			
            const response = await this.manageShift(formData);

            this.disableButton = false;
			
			loader.hide();
			
            if(response?.error && response?.error != null)
            {
              this.formErrors = response?.error;
			  
            }else if(response?.status == 200)
            {
              this.$emit('reload-data');
              notification.open({
                message:  response?.data?.message || 'Successfully Shift Added',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_SUCCESS_COLOR,
                },
              });

              this.formShift = {
                id:null,
                shift_name:'',
                min_start_time:null,
                start_time:null,
                max_start_time:null,
                min_end_time:null,
                end_time:null,
                max_end_time:null,
                break_time:0,
                recurring_shifts:false,
                repeat:'',
                weekdays:[],
                endon:new Date(),
                indefinite:'',
                tags:'',
                note:'',
                shift_id:null
              }
              this.$refs.closeModalRefOne.click()
            }
          } 
        
          
        
    },
    onDeleteShift()
    {
      this.$emit('delete-shift',this.deleteId);
    },
    closeShiftModal()
    {
      this.formShift = {
          id:null,
          shift_name:'',
          min_start_time:null,
          start_time:null,
          max_start_time:null,
          min_end_time:null,
          end_time:null,
          max_end_time:null,
          break_time:0,
          recurring_shifts:false,
          repeat:'',
          weekdays:[],
          endon:new Date(),
          indefinite:'',
          tags:'',
          note:'',
          shift_id:null
        }
    }
  },
  watch:{
    closeModal(newValue)
    {
      if(newValue)
      {
        this.$refs.closeModalRef.click()
      }
    },
    editShift(newValue)
    {

      console.log('newValue',newValue);
      if(newValue != null)
      {
        this.formShift.min_start_time = dayjs(newValue?.min_start_time, 'HH:mm:ss');
        this.formShift.max_end_time = dayjs(newValue?.max_end_time, 'HH:mm:ss');
        this.formShift.max_start_time = dayjs(newValue?.max_start_time, 'HH:mm:ss');
        this.formShift.min_end_time = dayjs(newValue?.min_end_time, 'HH:mm:ss');
        this.formShift.start_time = dayjs(newValue?.start_time, 'HH:mm:ss');
        this.formShift.end_time = dayjs(newValue?.end_time, 'HH:mm:ss');
        this.formShift.break_time = newValue?.break_time;
        this.formShift.indefinite = newValue?.indefinite;
        this.formShift.note = newValue?.note;
        this.formShift.recurring_shifts = newValue?.recurring_shifts;
        this.formShift.shift_name = newValue?.shift_name;
        this.formShift.id = newValue?.shift_id;
        this.formShift.weekdays = newValue?.weekdays.split(',');
        this.formShift.endon = newValue?.endon && newValue?.endon != null ? new Date(newValue?.endon) : null;
        this.formShift.repeat = newValue?.repeat;
        this.formShift.shift_id = newValue?.shift_id;
        this.formShift.tags = newValue?.tags;
      }
      else
      {
        this.formShift = {
          id:null,
          shift_name:'',
          min_start_time:null,
          start_time:null,
          max_start_time:null,
          min_end_time:null,
          end_time:null,
          max_end_time:null,
          break_time:0,
          recurring_shifts:false,
          repeat:'',
          weekdays:[],
          endon:new Date(),
          indefinite:'',
          tags:'',
          note:'',
          shift_id:null
        }
      }
      
    }
  },

};
</script>

