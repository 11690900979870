<template>
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
      <div class="page-wrapper" ref="formContainer">
            <div class="content container-fluid">
                <wages-breadcrumb @clean-wage-data="handleCleanWageData" title="Wages" path="Dashboard" text="Wages" text1="Add Wages" @clearImportForm="clearImportForm"> </wages-breadcrumb>
			  
			  <Form @submit="updatePerPage">
					<div class="row filter-row">
						<div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-12">
						
						  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
							<input
							  type="text"
							  class="form-control floating"
							  v-model="searchform.wages_name"
							  @focus="isFocused = true"
							  @blur="isFocused = searchform.wages_name !== ''"
							/>
							<label class="focus-label"> Title</label>
						  </div>
						</div>
						<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
						  <button class="btn btn-success w-100 submit-btn">Search</button>
						</div>
						<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" style="margin-left: 10px;">
						  <a href="javascript:;" class="btn btn-danger w-100 submit-btn" @click="clearForm"> Clear </a>
						</div>
					  </div>
				</Form>  
		
              <div class="row">
                  <div class="col-md-12">
                      <div class="showentries mb-3">
                      <label
                          >Show
                          <select v-model="pagination.pageSize" @change="updatePerPage">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          </select>
                          entries</label
                      >
                      </div>
                      <div class="table-responsive">
                          <a-table
                              class="stripped table-hover"
                              :columns="columns"
                              :data-source="data"
                          >
                              <template #bodyCell="{ column, record }">
                                  <!-- <template v-if="column.key === 'wages_id'">
                                      #{{ record.wages_id  }}
                                  </template> -->
                                  <template v-if="column.key === 'active'">
                                      {{ (record?.active) ? 'Active' : 'InActive' }}
                                  </template>
                                  <template v-else-if="column.key === 'action'">
                                      <div class="dropdown dropdown-action" v-if="hasEditPermission || hasDeletePermission">
                                          <a
                                              href="javascript:void(0);"
                                              class="action-icon dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              ><i class="material-icons">more_vert</i></a
                                          >
                                          <div class="dropdown-menu dropdown-menu-right">
                                              <a v-if="hasEditPermission"
                                              @click="editData(record)"
                                              class="dropdown-item"
                                              href="javascript:void(0);"
                                              data-bs-toggle="modal"
                                              data-bs-target="#wage_update_modal"
                                              ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                                              >
                                              <a v-if="hasDeletePermission"
                                              class="dropdown-item"
                                              href="javascript:void(0);"
                                              @click="activeData = record"
                                              data-bs-toggle="modal"
                                              data-bs-target="#delete_wages"
                                              ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                                              >
                                          </div>
                                      </div>
                                  </template>
                              </template>
                              
                          </a-table>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
    <!-- Delete Project Modal -->
  <div class="modal custom-modal fade" id="delete_wages" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Project</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
				  @click="deleteWagesAction"
				  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
				          ref="deletewageclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Wages Modal -->

    <!-- Update Wage Modal -->
    <div
    id="wage_update_modal"
    class="modal custom-modal fade"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Wage Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="update_wage_information_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Wage Name<span class="text-danger">*</span></label
                >
                <input type="text" class="form-control" v-model="editActiveData.wages_name"/>
                <span class="text-danger" v-if="errors && errors?.wages_name && errors?.wages_name != null">{{ errors?.wages_name[0]  }}</span>
              </div>
              <div class="input-block mb-3">
                <label class="col-form-label"
                  >Wage Status</label>
                <select class="form-select form-select-lg" v-model="editActiveData.active">
                    <option value="1">Active</option>
                    <option value="0">InActive</option>
                </select>
                <span class="text-danger" v-if="errors && errors?.active && errors?.active != null">{{ errors?.active[0]  }}</span>

              </div>
			  <div class="input-block mb-3">
                <label class="col-form-label"
                  >Time Frame</label>
                <select class="form-select form-select-lg" v-model="editActiveData.time_frame">
					<option value="0">Start of the Week</option>
					<option value="1">End of the Week</option>
                </select>
                <span class="text-danger" v-if="errors && errors?.time_frame && errors?.time_frame != null">{{ errors?.time_frame[0]  }}</span>

              </div>
            </div>
            
          </div>
          <div class="submit-section">
            <button class="btn btn-primary submit-btn" @click="updateWageAction()" :disabled="disableButton">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
 <!-- Update Wage Modal -->

 <!-- Import holiday Modal -->
 <div id="import_Wages" class="modal custom-modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
  <div class="modal-content">
    <div class="modal-header">
    <h5 class="modal-title">Import Wages</h5>
    <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    ref="impwagesclose"
    >
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
    <div class="input-block mb-3">
    <label class="col-form-label"
      >File <span class="text-danger">*</span>
    </label>
    <Field
      name="wages_import_file"
      type="file"
      class="form-control"
      :class="{ 'is-invalid': errors && errors?.wages_import_file }"
      @change="handleFileUpload($event)"
      accept=".xlsx,.xls"	
      ref="importFile"
      />
      <div class="invalid-feedback">{{ errors && errors?.wages_import_file }}</div>
      <div class="filesshow text-danger" id="file"></div>
      <p> <a :href="user_demo_file" target="_blank">Click here </a> for download Demo file </p>
    </div>
    <div class="submit-section">
    <button class="btn btn-primary submit-btn">Submit</button>
    </div>
    </Form>
    </div>
  </div>
  </div>
  </div>
  <!-- /Import wages Modal -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { notification } from "ant-design-vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from 'axios';

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};

const columns = [
  {
    title: "S.No.",
    dataIndex: "wages_id",
    key: "wages_id",
    customRender: ({ index }) => {
		  return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Wages Name",
    dataIndex: "wages_name",
    key: "wages_name",
    sorter: {
      compare: (a, b) => {
        a = a.wages_name.toLowerCase();
        b = b.wages_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    sorter: false,
  },
];

  export default {
    components: { Form,Field},
  data() {
    return {
		isFocused : false,
		searchform: {wages_name: ""},
        columns:columns,
        data:null,
        activeData:{},
        editActiveData:{
          wages_name:'',
          active:1,
		  time_frame: 1,
        },
        errors:[],
        disableButton:false,
        perpage: 10,
        pagination: pagination,
        hasReadPermission:null,
        hasEditPermission:null,
        hasDeletePermission:null,
        editfile:null,
    }
  },
  computed:{
    user_demo_file(){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}sample/wages.xlsx` 
    }
  },
  methods: {
    ...mapActions(['fetchWages','deleteWages','updateWages']),
    clearImportForm()
    {
      const fileInput = document.querySelector('input[name="wages_import_file"]');
      if (fileInput) {
      fileInput.value = '';
      }
      this.$refs.importFile.value = ''
      this.editfile = null
    },
    handleFileUpload(event){
      this.editfile = event.target.files[0];
    },
    onSubmit()
    {
    var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

    var form = new FormData();
        form.append('wages_import_file', this.editfile);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    };

    axios.post("/wages/import", form,config).then(response => {

      this.editfile = null
            this.$refs.importFile.value = ''
      loader.hide();

      this.updatePerPage()
      
      notification.open({
              message: response?.data?.message || 'Successfully Imported',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

      this.$refs.impwagesclose.click();

    }).catch(error =>{
      
      loader.hide();
      if(error.response){
          
        var response = (error.response);
          
        notification.open({
          message: response?.data?.message || 'Error',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
          background: process.env.VUE_APP_WARNING_COLOR,
          },
        });
        
        }else{
        
        notification.open({
          message: 'Server Error',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
          background: process.env.VUE_APP_WARNING_COLOR,
          },
        });
      }
    })

    },
    async deleteWagesAction(){
        if(this.activeData?.wages_id)
        {
            const response = await this.deleteWages(this.activeData?.wages_id);

            if(response?.status == 200)
            {
              const fetchedData = await this.updatePerPage();

              if (fetchedData !== null && fetchedData !== undefined && Array.isArray(fetchedData) ) {
                this.data = fetchedData;
              }
                
              this.$refs.deletewageclose.click();

              this.activeData = {}

                notification.open({
						message: 'Record Deleted',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_SUCCESS_COLOR,
						},
					});
            }
            else
            {
                this.activeData = {};
                this.$refs.deletewageclose.click();

                notification.open({
						message: 'Server Error',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
            }
        }
    },
    editData(record)
    {
        this.errors = []
        this.editActiveData = record
    },
    async updateWageAction(){
        this.disableButton = true;
        this.errors = []
       
            const response = await this.updateWages(this.editActiveData);
            if(response?.error && response.error != null)
            {
                this.errors = response.error;
                this.disableButton = false;
                notification.open({
                  message: response?.message || 'Something went wrong!',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });
            }
            else if(response.status == 200)
            {
                this.disableButton = false;
                this.editActiveData = {
                  wages_name:'',
                  active:'',
				  time_frame: '',
                };
                this.$refs.update_wage_information_close.click();

                const fetchedData = await this.updatePerPage();

                if (fetchedData !== null && fetchedData !== undefined && Array.isArray(fetchedData)) {
                  this.data = fetchedData;
                }

                notification.open({
                  message: 'Record Updated',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
                });
            }
    },
    handleCleanWageData(){
        this.editActiveData = {
          wages_name:'',
          active:'1',
          time_frame:'1'
        };
        this.errors = null;
    },
	clearForm(){
		
		this.searchform = {wages_name: ""};
		this.updatePerPage();
	
	},
    async updatePerPage(){
      var params = {
        params: { per_page: this.pagination.pageSize,wages_name:this.searchform.wages_name}
      };
	
	let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });
		
    const fetchedData = await this.fetchWages(params);
    if (fetchedData !== null && fetchedData !== undefined && Array.isArray(fetchedData)) {
      this.data = fetchedData;
    }
    else
    {
      notification.open({
        message: fetchedData?.message || 'Data not found',
        placement: "topRight",
        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
        style: {
          background: process.env.VUE_APP_WARNING_COLOR,
        },
      });
    }
     
		loader.hide();	 
    },
  },
  async created(){
    this.$store.dispatch('userPermissions', { module: 'wages', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
        if(response)
        {
          this.columns = [...columns,{
            title: "Action",
            sorter: false,
            key: "action",
          }];
        }
      })
    })

    this.$store.dispatch('userPermissions', { module: 'wages', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'wages', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

    this.updatePerPage();
  },
  setup() {
      const schema = Yup.object().shape({
        wages_import_file: Yup.string().required("File is required"),
      })
  
      return {
          schema
      }
    },
  
  }
</script>