<template>
  <div class="table-responsive">
    <table class="table custom-table mb-0">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th class="text-end">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in AdminClients" :key="item.id">
          <td>
            <h2 class="table-avatar">
              <router-link to="client-profile"
                >
                {{ item?.first_name }} {{ item?.last_name }} <span>{{ item.Role }}</span></router-link
              >
            </h2>
          </td>
          <td>{{ item.email }}</td>
          <td class="text-end">
            <div class="dropdown dropdown-action">
              <a
                href="javascript:;"
                class="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="material-icons">more_vert</i></a
              >
              <div class="dropdown-menu dropdown-menu-right">
                <router-link
                  class="dropdown-item"
                  :to="'clientprofile/' + item?.id"
                  ><i class="fa-solid fa-eye m-r-5"></i> View</router-link
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AdminClients from "@/assets/json/admin-clients.json";
import { notification } from "ant-design-vue";
import axios from "axios";

export default {
  data() {
    return {
      AdminClients: [],
    };
  },
  methods: {
    loadClients() {
      const token = window.localStorage.getItem("token");


      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;


      axios
        .get("/client", { params: { per_page: 3 } }) // Fetch only 3 clients
        .then((response) => {
          // console.log(response.data.data.record.data.slice(0, 3));
          console.log(response.data);
          const records = response.data?.data?.record?.data;
          this.AdminClients = Array.isArray(records) ? records.slice(0, 3) : [];
        })
        .catch((error) => {
          var response = error.response;
        console.log(error);


          if (
            error.response.status == 401 &&
            response.data.message == "Unauthenticated."
            ) {
            localStorage.clear();


            notification.open({
              message: "Please Login",
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });


            this.$router.push({ name: "login" }).catch((error) => {});
          } else {
            this.errorMessage = error.message;
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        });
    },
  },
  mounted() {
    this.loadClients(); // Call the method without arguments
  },
};


</script>
