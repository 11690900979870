<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <reports-header :title="title" :path="path" :text="text" :text1="text1" :search_data="search_data" :columns="originalColumn"/>
        <!-- /Page Header -->
        <!-- Search Filter -->
        <employee-reports-filter @filter-data="filterData" :hasEmployeeStatus="true" />
        <!-- /Search Filter -->
        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="originalColumn"
                :data-source="data"
                :pagination="pagination"
				        @change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'EmployeeName'">
                    <h2 class="table-avatar">
                        <router-link :to="'profile/'+record?.id" class="avatar"
                          >
                              <img v-if="record?.avatar"
                              :src="getEmployeeAvatar(record.avatar)"
                              alt="User Image"
                              @error="handleImageError(record)"
                              />
                              <img v-else
                              :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                                  alt="User Image" 
                              />
                        </router-link>
                        <router-link :to="'profile/'+record?.id"
                          >{{ record?.first_name }} {{ record?.last_name }} <span>{{ (record?.employee_id) ? '- #'+record?.employee_id : '' }}</span></router-link
                        >
                      </h2>
                  </template>
                  <template v-if="column.key === 'EmployeeType'">
                    <p>Employee</p>
                  </template>
                  <template v-else-if="column.key === 'Department'">
                    <p>{{ (record?.department && record?.department?.name) ? record?.department?.name : '' }}</p>
                  </template>
                  <template v-if="column.key === 'Designation'">
                    <p>{{ (record?.designation && record?.designation?.name) ? record?.designation?.name : '' }}</p>
                  </template>
                  <template v-if="column.key === 'JoiningDate'">
                    <p>{{ formatDate(record?.joining_date) || record?.joining_date }}</p>
                  </template>
                  <template v-if="column.key === 'DOB'">
                    <p>{{ formatDate(record?.birth_date) || record?.birth_date }}</p>
                  </template>
                  <template v-else-if="column.key === 'Email'">
                    <div class="text-info">{{ record?.email }}</div>
                  </template>
                  <template v-else-if="column.key === 'MartialStatus'">
                    <div>{{ record?.marital_status && record?.marital_status == 1 ? 'Married' : record?.marital_status == 0 ? 'Single' : '-'  }}</div>
                  </template>
                  <template v-else-if="column.key === 'Gender'">
                    <div>{{ record?.gender && record?.gender == 'm' ? 'Male' : record?.gender == 'f' ? 'Female' : record?.gender == 'o' ? 'Other' : '-'  }}</div>
                  </template>
                  <template v-else-if="column.key === 'Salary'">
                    <div>{{ record?.salary_amount ? '$'+record?.salary_amount : '-'  }}</div>
                  </template>
                  <template v-else-if="column.key === 'Address'">
                    <div>{{ record?.address ? record?.address : '-'  }} {{ record?.state_id ? ' ,'+record?.state_id : ''  }} {{ record?.country ? ' ,'+record?.country : ''  }}</div>
                  </template> 
                  <template v-else-if="column.key === 'EmercencyContactDetails'">
                    <div>{{ record?.employee_contact && record?.employee_contact?.length ? fetchEmergencyContact(record?.employee_contact) : '-'  }}</div>
                  </template>
                  <template v-else-if="column.key === 'Experience'">
                    <div>{{ record?.employee_experience && record?.employee_experience?.length ? calculateTotalExperience(record?.employee_experience) : record?.employee_experience?.length  }}</div>
                  </template>
                  <template v-else-if="column.key === 'TerminatedDate'">
                    <p>{{ formatDate(record?.termination_date) || record?.termination_date }}</p>
                  </template>
                  <template v-else-if="column.key === 'RelievingDate'">
                    <p>{{ formatDate(record?.relieving_date) || record?.relieving_date }} </p>
                  </template>
                  <template v-else-if="column.key === 'Status'">
                    <button v-if="record?.status == 1" class="btn btn-outline-success btn-sm">Active</button>
                    <button v-if="record?.status == 0" class="btn btn-outline-danger btn-sm">Inactive</button>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <!-- /Page Wrapper -->

     <!-- Update employee_manage_colvis Modal -->
     <div
     id="employee_manage_colvis"
     class="modal custom-modal fade"
     role="dialog"
   >
     <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title">Manage Column Visibility</h5>
           <!-- <small>select the column from the default that you want to hide</small> -->
           <button
             type="button"
             class="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
             ref="modal_team_information_close"
           >
             <i class="fas fa-times"></i>
           </button>
         </div>
         <div class="modal-body">
           <div class="row">
                 <div class="col-md-6" v-for="(column,key) in columns_" :key="column.key">
                  <div class="input-block mb-3 d-flex">
                    
                    <div class="form-switch" style="margin-right: 5px;">
                      <input
                        v-model="column.visibility"
                        type="checkbox"
                        class="form-check-input"
                        :id="'customSwitch_'+column.key"
                        value="false"
                        :checked="column.visibility"
                      />
                    </div>
                    <label class="col-form-label"> {{column?.title}} </label>
                  </div>
                 </div>
           </div>
           <!-- <div class="submit-section">
             <button class="btn btn-primary submit-btn" @click="" :disabled="disableButton">Submit</button>
           </div> -->
         </div>
       </div>
     </div>
   </div>
  <!-- Update employee_manage_colvis Modal -->
  </div>
</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};
export default {
  data() {
    return {
      title: "Employee Report",
      path: "Dashboard",
      text: "Employee Report",
      text1: "Export Excel",
      columns_:[
        {
          title: "Employee Name",
          dataIndex: "EmployeeName",
          key: "EmployeeName",
          sorter: {
            compare: (a, b) => {
              a = a.first_name.toLowerCase();
              b = b.first_name.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Employee Type",
          dataIndex: "EmployeeType",
          key: "EmployeeType",
          sorter: {
            compare: (a, b) => {
              a = a?.EmployeeType?.toLowerCase();
              b = b?.EmployeeType?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Email",
          dataIndex: "Email",
          key: "Email",
          sorter: {
            compare: (a, b) => {
              a = a.email.toLowerCase();
              b = b.email.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Department",
          dataIndex: "Department",
          key: "Department",
          sorter: {
            compare: (a, b) => {
              a = a?.department?.name?.toLowerCase();
              b = b?.department?.name?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Designation",
          dataIndex: "Designation",
          key:"Designation",
          sorter: {
            compare: (a, b) => {
              a = a?.designation?.name?.toLowerCase();
              b = b?.designation?.name?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        }, 
        {
          title: "Joining Date",
          dataIndex: "JoiningDate",
          key: "JoiningDate",
          sorter: {
            compare: (a, b) => {
              a = a.joining_date.toLowerCase();
              b = b.joining_date.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "DOB",
          dataIndex: "DOB",
          key: "DOB",
          sorter: {
            compare: (a, b) => {
              a = a.birth_date.toLowerCase();
              b = b.birth_date.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Martial Status",
          dataIndex: "MartialStatus",
          key: "MartialStatus",
          sorter: {
            compare: (a, b) => {
              a = a.marital_status.toLowerCase();
              b = b.marital_status.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Gender",
          dataIndex: "Gender",
          key: "Gender",
          sorter: {
            compare: (a, b) => {
              a = a.gender.toLowerCase();
              b = b.gender.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Terminated Date",
          dataIndex: "TerminatedDate",
          key:'TerminatedDate',
          sorter: {
            compare: (a, b) => {
              a = a?.termination_date?.toLowerCase();
              b = b?.termination_date?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Relieving Date",
          dataIndex: "RelievingDate",
          key: "RelievingDate",
          sorter: {
            compare: (a, b) => {
              a = a?.relieving_date?.toLowerCase();
              b = b?.relieving_date?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Salary",
          dataIndex: "Salary",
          key: "Salary",
          sorter: {
            compare: (a, b) => {
              a = a.salary_amount.toLowerCase();
              b = b.salary_amount.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Address",
          dataIndex: "Address",
          key: "Address",
          sorter: {
            compare: (a, b) => {
              a = a.address.toLowerCase();
              b = b.address.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Contact Number",
          dataIndex: "contact_number",
          key: "contact_number",
          sorter: {
            compare: (a, b) => {
              a = a.contact_number.toLowerCase();
              b = b.contact_number.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
        {
          title: "Emergency Contact Details",
          dataIndex: "EmercencyContactDetails",
          key: "EmercencyContactDetails",
          hidden:false,
          visibility:true
        },
        {
          title: "Experience",
          dataIndex: "Experience",
          key: "Experience",
          hidden:false,
          visibility:true
        },
        {
          title: "Status",
          dataIndex: "Status",
          key: "Status",
          sorter: {
            compare: (a, b) => {
              a = a?.Status?.toLowerCase();
              b = b?.Status?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          hidden:false,
          visibility:true
        },
      ],
      data:[],
      pagination,
      defaultAvatar:'avatar-02.jpg',
      departments:[],
      search_data:null
    };
  },
  name: "employee-reports",
  methods:{
    calculateTotalExperience(jobs)
    {
      let totalMonths = 0;
      let totalDays = 0;
      
      jobs.forEach(job => {
          const fromDate = new Date(job.job_period_from);
          const toDate = new Date(job.job_period_to);

          // Calculate the difference in months and days between two dates
          const yearDiff = toDate.getFullYear() - fromDate.getFullYear();
          const monthDiff = toDate.getMonth() - fromDate.getMonth();
          const dayDiff = toDate.getDate() - fromDate.getDate();

          if (yearDiff === 0 && monthDiff === 0) {
              // If less than a month, add the difference in days
              totalDays += dayDiff;
          } else {
              // Calculate the difference in months
              totalMonths += yearDiff * 12 + monthDiff;
              // Add remaining days if not a full month
              totalDays += dayDiff;
          }
      });

      // Convert total months into years and months
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      // Format the result
      let result = '';
      if (years > 0) {
          result += `${years} Year`;
          if (years > 1) result += 's';
      }
      if (months > 0) {
          result += ` ${months} Month`;
          if (months > 1) result += 's';
      }
      if (totalMonths === 0 && totalDays > 0) {
          result += `${totalDays} Day`;
          if (totalDays > 1) result += 's';
      }

      return result.trim() != '0' ?  result.trim() : '-';
    },
    fetchEmergencyContact(contacts)
    {
      const allNumbers = contacts.flatMap(contact => [contact.contact_number_1, contact.contact_number_2]);
      const uniqueNumbers = [...new Set(allNumbers)].filter(number => number !== null && number !== undefined);
      return uniqueNumbers.join(', ');
    },
    fetchJoiningDate(date){
      if(date)
      {
        return moment(date).format('DD MMM YYYY') || '-';
      }
      else
      {
        return '-'
      }
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(record) {
      record.avatar = null;
      record.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    handleTableChange(pagesize){
      var params = {
        params: { per_page: pagesize.pageSize,page:pagesize.current }
      };
      this.fetchData(params);
    },
    filterData(searchData = null)
    {
      let params = null;
      if(searchData)
      {

        this.search_data = null;
        this.$nextTick(()=>{
          this.search_data = searchData;
        })
        
        params = {
          params: { 
            per_page: this.pagination.pageSize,
            ...searchData
          }
        };
      }

      this.fetchData(params);
      
    },

    updatePerPage(){
      var params = {
            params: { per_page: this.pagination.pageSize }
          };
      this.fetchData(params);
      
    },
    fetchData(params = null ){
      var token = window.localStorage.getItem("token");
		  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		  axios.defaults.headers.common["Authorization"] = "Bearer " + token;


      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: false
      });

      if(params == null)
      {
        params = {
          params: { per_page: this.pagination.pageSize }
        };
      }

          axios.get('/report/employee-report',params).then(response => {
            this.pagination.total = response.data.data.total;
            this.pagination.current = response.data.data.current_page;
            this.pagination.pageSize = response.data.data.per_page;

        
            
            this.data = response?.data?.data?.data || [];

            loader.hide();

          }).catch((error) => {

            loader.hide();
			  
            var response = (error.response);
            
            if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
            
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              
            this.$router.push({name: 'login'}).catch(error => {}) 
            
            }else{
            
            this.errorMessage = error.message;
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              
            }
          });
    }
  },
  computed:{
    originalColumn()
    {
      return this.columns_.filter(column => column.visibility);
    }
  },
  mounted()
  {
    this.fetchData()
  }
};
</script>
