<template>
  <div ref="formContainer">
    <div class="card leave-box" id="leave_annual">
      <div class="card-body">
        <div class="h3 card-title with-switch">
          {{ leaveType?.type }}
        <!-- ==  {{ leaveType }} == -->
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              class="onoffswitch-checkbox"
              :id="'switch_annual'+leaveType.id"
              v-model="switchValue"
              @change="updateLeaveTypeStatus"
            />
            <label class="onoffswitch-label" :for="'switch_annual'+leaveType.id">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
        <div class="leave-item">
          <!-- Annual Days Leave -->
          <div class="leave-row">
            <div class="leave-left">
              <div class="input-box">
                <div class="input-block mb-3">
                  <label class="col-form-label">Days</label>
                  <input type="number" v-model="leave_type_form.leave_type_day" :disabled="isEditings" class="form-control" />
                </div>
              </div>
            </div>
            <div class="leave-right" v-if="switchValue">
              <button @click="toggleEdit" class="btn" :class="editBtnClass">
                {{ editBtnText }}
              </button>
              <button v-if="isEditing" class="btn btn-primary leave-save-btn" type="submit" @click="saveEdit('leave_type_day')">
                Save
              </button>
            </div>
          </div>
          <!-- /Annual Days Leave -->

          <!-- Carry Forward -->
          <div class="leave-row">
            <div class="leave-left">
              <div class="input-box">
                <label class="d-block col-form-label">Carry forward</label>
               
                <div class="input-block input-block-d d-flex">
                  
                  <div class="form-check form-check-d">
                    <input
                      type="radio"
                      :name="'inlineRadioOptions_'+leave_type_form?.id"
                      id="carry_no"
                      :value="0"
                      v-model="leave_type_form.is_carry_forward"
                      :disabled="!isEditingCarry || !switchValue"
                    />
                  </div>
                  <label class="col-form-label col-form-label-d" style="margin-left:10px" for="carry_no">No</label>
                  
                  <div class="form-check form-check-d">
                    <input
                      type="radio"
                      :name="'inlineRadioOptions_'+leave_type_form?.id"
                      id="carry_yes"
                      :value="1"
                      v-model="leave_type_form.is_carry_forward"
                      :disabled="!isEditingCarry || !switchValue"
                    />
                  </div>
                  <label class="col-form-label col-form-label-d" style="margin-left:10px" for="carry_no">Yes</label>
                </div>
                <div class="input-box">
                  <div class="input-group">
                    <span class="input-group-text">Max</span>
                    <input
                      type="text"
                      class="form-control"
                      :disabled="!isEditingCarry || !switchValue"
                      v-model="leave_type_form.carry_forward_max"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="leave-right">
              <button @click="toggleEditCarry" class="btn" :class="editBtnClassCarry">
                {{ editBtnTextCarry }}
              </button>
              <button
                v-if="isEditingCarry && switchValue"
                class="btn btn-primary leave-save-btn"
                type="submit"
                @click="saveEdit('carry_forward')"
              >
                Save
              </button>
            </div>
          </div>
          <!-- /Carry Forward -->

          <!-- Earned Leave -->
          <div class="leave-row">
            <div class="leave-left">
              <div class="input-box">
                <label class="d-block col-form-label">Earned leave</label>
                <div class="input-block input-block-d d-flex">
                  <div class="form-check form-check-d">
                    <input
                      type="radio"
                      :name="'inlineRadioOptionss_'+leave_type_form?.id"
                      id="earned_no"
                      value="0"
                      v-model="leave_type_form.is_earned_leave"
                      :disabled="!isEditingleave || !switchValue"
                    />

                    <label class="col-form-label col-form-label-d" style="margin-left:10px" for="earned_no">No</label>
                  </div>
                  <div class="form-check form-check-d">
                    <input
                    
                      type="radio"
                      :name="'inlineRadioOptionss_'+leave_type_form?.id"
                      v-model="leave_type_form.is_earned_leave"
                      id="earned_yes"
                      value="1"
                      :disabled="!isEditingleave || !switchValue"
                    />

                    <label class="col-form-label col-form-label-d" style="margin-left:10px" for="earned_yes">Yes</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="leave-right">
              <button @click="toggleEditleave" class="btn" :class="editBtnClassleave">
                {{ editBtnTextleave }}
              </button>
              <button
                v-if="isEditingleave && switchValue"
                class="btn btn-primary leave-save-btn"
                type="submit"
                @click="saveEdit('is_earned_leave')"
              >
                Save
              </button>
            </div>
          </div>
          <!-- /Earned Leave -->
        </div>

        <!-- Custom Policy -->
        <div class="custom-policy">
          <div class="leave-header">
            <div class="title">Custom policy</div>
            <div class="leave-action">
              <button
                class="btn btn-sm btn-primary"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add_custom_policy"
                @click="setLeaveTypePolicyID({...leaveType})"
                v-if="switchValue"
              >
                <i class="fa-solid fa-plus"></i> Add custom policy
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover table-nowrap leave-table mb-0">
              <thead>
                <tr>
                  <th class="l-name">Name</th>
                  <th class="l-days">Days</th>
                  <th class="l-assignee">Assignee</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cPolicy,index) in customPolicies" :key="index">
                  <td>{{cPolicy?.leave_policy_name}}</td>
                  <td>{{cPolicy?.leave_policy_day}}</td>
                  <td>
                    <a href="javascript:;" class="avatar" v-if="cPolicy?.policyUsers && cPolicy?.policyUsers?.length"
                        ><img v-if="record?.avatar"
                          :src="getEmployeeAvatar(cPolicy?.policyUsers[0].avatar)"
                          alt="User Image"
                          @error="handleImageError(cPolicy?.policyUsers[0])"
                          />
                          <img v-else
                          :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                              alt="User Image" 
                          />
                    </a>
                    <a  v-if="cPolicy?.policyUsers && cPolicy?.policyUsers?.length" href="javascript:;">{{ cPolicy?.policyUsers[0]?.first_name }} {{ cPolicy?.policyUsers[0]?.last_name }} {{ cPolicy?.policyUsers?.length > 1 ? ' and + '+(cPolicy?.policyUsers?.length - 1) : ''  }}</a>
                  </td>
                  <td class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        aria-expanded="false"
                        data-bs-toggle="dropdown"
                        class="action-icon dropdown-toggle"
                        href="javascript:;"
                        ><i class="material-icons">more_vert</i></a
                      >
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          href="javascript:;"
                          class="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#add_custom_policy"
                          @click="editFormDataSet(cPolicy)"
                          v-if="switchValue"
                          ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                        >
                        <a
                          href="javascript:;"
                          class="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_custom_policy"
                          @click="$store.commit('SET_DELETE_POLICY_ID',cPolicy?.leave_type_policy_id)"
                          v-if="switchValue"
                          ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="!customPolicies?.length">
                  <td colspan="4" class="text-center">
                    No records found !
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /Custom Policy -->
      </div>
    </div>
    <leave-settings-model :closeModel="closeModel" :clearData="clearData" @deleteLeavePolicy="delete_leave_policy" :addLeaveTypePolicyID="editPolicyId" :editFormData="editFormData" @reloadData="reloadData" />
  </div>
</template>

<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  emits:['reloadLeaveTypeData','onEditPolicyID','onEditLeaveTypePolicyData'],
  props:{
    leaveType: {
      type: Object,
      required: false,
      default: () => null,
    },
    editPolicyId:{
      type: Number,
      required: false,
      default: () => null
    },
    editPolicyData:{
      type: Object,
      required: false,
      default: () => null
    },
  },
  data() {
    return {
      closeModel:false,
      editFormData:null,
      leave_type_form:{
        leave_type_day:'',
        carry_forward_max:'',
        is_carry_forward:0,
        is_earned_leave:'',
        leave_type_id:'',
      },
      addLeaveTypePolicyID:null,
      carryOption: "no",
      days: "", // Initialize days
      isEditing: false,
      isEditings: true,
      maxValue: "",
      isEditingCarry: false,
      isEditingCarrys: true,
      isEditingleave: false,
      isEditingleaves: true,
      switchValue: true,
      max_earned_leave:false,
      deleteLeavePolicyID:null,
      clearData:false
    };
  },
  computed: {
    customPolicies()
    {
      return this?.leaveType?.leave_type_policies || [];
    },
    editBtnClass() {
      return this.isEditing ? "btn btn-white leave-cancel-btn" : "leave-edit-btn";
    },
    editBtnText() {
      return this.isEditing ? "Cancel" : "Edit";
    },
    editBtnClassCarry() {
      return this.isEditingCarry ? "btn btn-white leave-cancel-btn" : "leave-edit-btn";
    },
    editBtnTextCarry() {
      return this.isEditingCarry ? "Cancel" : "Edit";
    },
    editBtnClassleave() {
      return this.isEditingleave ? "btn btn-white leave-cancel-btn" : "leave-edit-btn";
    },
    editBtnTextleave() {
      return this.isEditingleave ? "Cancel" : "Edit";
    },
  },
  methods: {
    delete_leave_policy()
    {
      var token = window.localStorage.getItem("token");
		  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});

      axios.delete('/leave-settings/policy/'+this.$store?.state?.delete_policy_id).then(response =>{

        this.$store.commit('SET_DELETE_POLICY_ID',null)

        this.deleteLeavePolicyID = null

        this.isEditing = false;
        this.isEditingCarry = false;
        this.isEditingleave = false;
        this.isEditings = true;

        this.closeModel = false;
          this.$nextTick(()=>{
            this.closeModel = true;
          })

        this.reloadData()

        loader.hide();
        notification.open({
            message:  response?.data?.message || 'Successfully Data deleted',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });

          

      }).catch(error =>{
        loader.hide();
        this.$store.commit('SET_DELETE_POLICY_ID',null)
        this.deleteLeavePolicyID = null
       this.closeModel = false;
        this.closeModel = true;


          if(error.response){

            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
      })
    },
    deleteFormDataSet(deleteData){
      this.deleteLeavePolicyID = deleteData?.leave_type_policy_id;
    },
    setLeaveTypePolicyID(val){
      this.clearData = false
      this.clearData = true;
      this.$emit('onEditPolicyID',val?.id);
    },
    updateLeaveTypeStatus()
    {
      var token = window.localStorage.getItem("token");
		  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});

      axios.put('/leavetypes/status-update/'+this?.leave_type_form?.id,{'status':this.switchValue}).then(response =>{

        this.isEditing = false;
        this.isEditingCarry = false;
        this.isEditingleave = false;
        this.isEditings = true;

        loader.hide();
        notification.open({
            message:  response?.data?.message || 'Successfully Data Updated',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });

      }).catch(error =>{
        loader.hide();

          if(error.response){

            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
      })

    },  
    reloadData()
    {
      this.editFormData = null;
      this.deleteLeavePolicyID = null;
      this.$emit('reloadLeaveTypeData');
    },
    editFormDataSet(cPolicy)
    {
      this.$emit('onEditLeaveTypePolicyData',cPolicy);
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(record) {
      record.avatar = null;
      record.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    async saveEdit(keyName)
    {
      const formData = new FormData();

      if(keyName == 'leave_type_day')
      {
        formData.append('leave_type_day',this.leave_type_form?.leave_type_day);
      }

      if(keyName == 'carry_forward')
      {
        formData.append('is_carry_forward',this.leave_type_form?.is_carry_forward != null ? this.leave_type_form?.is_carry_forward : '');
        formData.append('carry_forward_max',this.leave_type_form?.carry_forward_max);
      }

      if(keyName == 'is_earned_leave')
      {
        formData.append('is_earned_leave',this.leave_type_form?.is_earned_leave != null ? this.leave_type_form?.is_earned_leave : '');
      }
      var token = window.localStorage.getItem("token");
		  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});

      await axios.put('/leave-settings/'+this?.leave_type_form?.id,formData).then(response => {

        loader.hide();

        this.isEditing = false;
        this.isEditingCarry = false;
        this.isEditingleave = false;
        this.isEditings = true;

        notification.open({
            message:  response?.data?.message || 'Successfully Data Updated',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });

        
      }).catch(error => {
        loader.hide();

        if(error.response){
        
          var response = (error.response);
            
          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
       })

    },
    toggleEdit() {
      if (!this.switchValue) {
        return; // Don't allow editing if the switch is off
      }
      if (this.isEditing) {
        // Perform actions when cancelling editing
        // For example, you might reset the input or update the data on cancel
      }

      this.isEditing = !this.isEditing;
      this.isEditings = !this.isEditings;
    },
    toggleEditCarry() {
      if (!this.switchValue) {
        return; // Don't allow editing if the switch is off
      }
      if (this.isEditingCarry) {
        // Perform actions when cancelling editing
        // For example, you might reset the input or update the data on cancel
      }
      this.isEditingCarry = !this.isEditingCarry;
      this.isEditingCarrys = !this.isEditingCarrys;
    },
    toggleEditleave() {
      if (!this.switchValue) {
        return; // Don't allow editing if the switch is off
      }
      if (this.isEditingleave) {
        // Perform actions when cancelling editing
        // For example, you might reset the input or update the data on cancel
      }
      this.isEditingleave = !this.isEditingleave;
      this.isEditingleaves = !this.isEditingleaves;
    },
  },
  watch:{
    editPolicyData:{
      handler(newVal){
        this.editFormData = null;
        this.$nextTick(()=>{
          this.editFormData = newVal
        })
      },
      immediate: true
    },
    leaveType:{
      handler(newVal){
      this.leave_type_form.leave_type_day = newVal?.leave_type_day || '';
      this.leave_type_form.is_carry_forward = newVal?.is_carry_forward ? 1 : 0;
      this.leave_type_form.carry_forward_max = newVal?.carry_forward_max || '';
      this.leave_type_form.is_earned_leave = newVal?.is_earned_leave ? 1 : 0;
      this.leave_type_form.id = newVal?.id || null;
      this.switchValue = newVal?.status == 0 ? false : newVal?.status == 1 ? true : '' ;
      },
      immediate: true
    }
  }
};
</script>
<style>
.input-block-d {
  display: flex;
  justify-content: start; 
  align-items: center; 
}

.form-check-d {
  display: flex;
  align-items: center; 
}

.col-form-label-d {
  margin-left: 5px; 
  display: inline-block; 
}
</style>
