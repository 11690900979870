<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <clients-header :title="title" :path="path" :text="text" :text1="text1" />
        <!-- /Page Header -->

        <!-- Search Filter -->
        <clients-filter @reload-data="searchData" @clear-data="clearData" :Companies="Companies" :searchform="searchform"></clients-filter>
        <!-- Search Filter -->

        <div class="row staff-grid-row">
          <div
            class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
            v-for="item in Employees"
            :key="item?.id"
          >
            <div class="profile-widget">
              <div class="profile-img" >
                <router-link :to="'clientprofile/'+item?.id" class="avatar"
                  ><img v-if="item?.avatar && item?.avatar != null"
                    :src="getEmployeeAvatar(item?.avatar)"
                    alt="User Image"
                    @error="handleImageError(item)"
                />
                <img v-else
                :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                    alt="User Image"
                />
              </router-link>
              </div>
              <div class="dropdown profile-action">
                <a
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="material-icons">more_vert</i></a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a 
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_client"
                    @click="employee(item)"
                    ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                  >
                  <a 
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_client"
                    @click="employee(item)"
                    ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                  >
                </div>
              </div>
              <h4 class="user-name m-t-10 mb-0 text-ellipsis">
                <router-link :to="'profile/'+item?.id">{{ item?.first_name }} {{ item?.last_name }}</router-link>
              </h4>
              <div class="small text-muted">{{ (item?.designation && item?.designation.name) ? item?.designation.name : '-' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="raw">
        <div class="col-12 text-center">
          <a-pagination @change="onChange" v-model:current="pagination.current"
          v-model:pageSize="pagination.pageSize" :total="pagination.total" show-less-items></a-pagination>
        </div>
      </div>
      <!-- /Page Content -->
      <client-model :employeeData="employeeData" :createform="createform" :Companies="Companies" @refreshClients="refreshClients" ref="clientmodel"></client-model>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import Clients from "@/assets/json/clients.json";

import axios from 'axios';
import { notification } from "ant-design-vue";

export default {
  data() {
    return {
      title: "Clients",
      path: "Dashboard",
      text: "Clients",
      text1: "Add Client",
      Clients: [],
      Employees: [],
	  data: [],
	  pagination:{
        total:0,
        current:0,
        pageSize:12
      },
	  defaultAvatar:'avatar-02.jpg',
	  createform : [],
	  Companies: [],
	  employeeData:null,
	  searchform:{clientId:"",clientName:"",companyid:""},
    };
  },
  methods: {
	clearData(){
	  this.searchform = {clientId:"",clientName:"",companyid:""};
	  this.loadCommonData([]);
	},
	searchData(searchData){
	
	  console.log(searchData);
	
      var params = {
        params: { per_page: this.pagination.pageSize,clientId:this.searchform.clientId,clientName:this.searchform.clientName,companyid:this.searchform.companyid }
      };
      this.loadCommonData(params);
	  
    },
	employee(employee){
       this.employeeData = employee;
    },
	refreshClients(){
		
    	var params = {
        params: { per_page: this.pagination.pageSize }
      };
      
      this.loadCommonData(params);
	},
	getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
	handleImageError(item) {
      item.avatar = null;
      item.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
	onChange(current){
	
      this.pagination.current = current;

      var params = {
        params: { per_page: this.pagination.pageSize,page:current,clientId:this.searchform.clientId,clientName:this.searchform.clientName,companyid:this.searchform.companyid }
      };
      
      this.loadCommonData(params);
    },
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/client", params)
			.then((response) => {
				
				console.log(response.data.data);
				
				this.pagination.total = response.data.data.record.total;
				this.pagination.current = response.data.data.record.current_page;
				this.pagination.pageSize = response.data.data.record.per_page;
				
				this.Employees = response.data.data.record.data || [];
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	loadCompanies(){
	
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/company", [])
			.then((response) => {
				
				console.log(response.data.data.record);
				
				this.Companies = response.data.data.record;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	}
  },
  mounted() {
	this.loadCommonData([]);
	this.loadCompanies([]);
  }
};
</script>
