<template>
  <!-- Search Filter -->
  <div class="row filter-row">
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus focused">
				<vue-select
				  :options="employee_list_dd"
				  id="projectreportname"
				  v-model="employeeID"
				  placeholder="-"
				/>

				<label class="focus-label">Employee Name</label>
			  </div>
    </div>

    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus select-focus">
        <div class="input-block mb-3 form-focus focused">
          <vue-select
            :options="department"
            id="projectreportdepartment"
            v-model="department_id"
            placeholder="-"
          />

          <label class="focus-label">Department</label>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus focused">
        <div class="cal-icon">
          <datepicker
            v-model="startdate"
            placeholder="Choose Date"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
            @update:model-value="searchSchedule"
          />
        </div>
        
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus focused">
        <div class="cal-icon">
          <datepicker
            v-model="startdateone"
            placeholder="Choose Date"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
            @update:model-value="searchSchedule"
          />
        </div>
        
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <a href="javascript:;" class="btn btn-success w-100 submit-btn" @click="searchSchedule"> Search </a>
    </div>
    <div class="col-sm-6 col-md-2">
      <a href="javascript:;" class="btn btn-danger w-100 submit-btn" @click="clearFilter"> Clear </a>
    </div>
  </div>
  <!-- Search Filter -->
</template>

<script>
import { ref } from "vue";
import moment from 'moment';
const currentDate = ref(new Date(moment()));
const currentDateOne = ref(new Date(moment().add(5, 'days')));
import { notification } from "ant-design-vue";
import axios from 'axios';
export default {
  emits:['search-schedule'],
  props:{
    sDate: {
      type: [String, Object],
      required: false,
      default: () => null,
    },
    eDate: {
      type: [String, Object],
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      select2: null,
      Finance: [
        "All Department",
        "Finance",
        "Finance and Management",
        "Hr & Finance",
        "ITech",
      ],
      startdate: currentDate,
      dateFormat: 'MM-dd-yyyy',
      startdateone: currentDateOne,
      employeeID: "",
      department_id:"",
      isFocused: false,
      department:[],
      employee_list_dd : []
    };
  },
  methods: {
    addFocusClass() {
      this.isFocused = true;
    },
    removeFocusClass() {
      this.isFocused = false;
    },
    clearFilter()
    {
      this.startdate = currentDate 
      this.startdateone = currentDateOne
      this.department_id = null
      this.employeeID = null
      this.searchSchedule()
    },
    loadAllEmployee(params){
		
      var token = window.localStorage.getItem("token");
    
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      axios.get("/employees/all", params)
        .then((response) => {
          this.employee_list_dd = []

          for(let i=0;i<response?.data?.data.length;i++){
            this.employee_list_dd.push({"id":response.data.data[i].id,"text":response.data.data[i].first_name+" "+response.data.data[i].last_name});
          }
              
        }).catch((error) => {
          console.log('error',error);
          var response = (error.response);
          
          if(error.response?.status == 401 && response.data.message == 'Unauthenticated.'){
          
          localStorage.clear();
          
          notification.open({
              message: 'Please Login',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          this.$router.push({name: 'login'}).catch(error => {}) 
          
          }else{
          
          this.errorMessage = error.message;
          notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }
          
        });
        
    },
    searchSchedule(){
      
      if(this.startdate == null || this.startdateone == null || this.startdate == '' || this.startdateone == '')
      {
        notification.open({
              message: 'please select start date and end date both for filter data',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
      }
      else{

        if(new Date(this.startdate) > new Date(this.startdateone))
        {
          const start_date = this.startdateone;
          this.startdateone =  this.startdate;
          this.startdate = start_date
        }

        const params = {
          employee_id:this.employeeID,
          department_id:this.department_id,
          start_date:moment(this.startdate).format('YYYY-MM-DD'),
          end_date:moment(this.startdateone).format('YYYY-MM-DD')
        }
        this.$emit('search-schedule',params);
      }
      
    }
  },
  created()
  {

    this.startdate = currentDate 
    this.startdateone= currentDateOne

    var token = window.localStorage.getItem("token");
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get('/departments/all').then(response => {
      this.department = []
      if(response?.data?.data && response?.data?.data?.length)
      {
        this.department = [{id:'',text:'Select'}];

        for(let i=0;i<response?.data?.data.length;i++){
          this.department.push({"id":response.data.data[i].id,"text":response.data.data[i].name});
        }
      }
      else
      {
        this.department = [{id:'',name:'No Records'}];
      }
    }).catch(err => {
      console.log('err',err);
    })

    this.loadAllEmployee([])
  },
  watch:{
    sDate(newValue){
      if(newValue?._i)
      {
        this.startdate = new Date(newValue?._i);
      }
    },
    eDate(newValue){
      if(newValue?._i)
      {
        this.startdateone = new Date(newValue?._i);
      }
    }
  },
};
</script>
