<template>
  <!-- Add Schedule Modal -->
  <div id="add_schedule" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ scheduleForm?.schedule_id && scheduleForm?.schedule_id != null ? 'Edit' : 'Add' }} Schedule</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeModalRef"
            @click="clickCloseModel"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
              <!-- <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Department <span class="text-danger">*</span></label
                  >
                  <select @change="handleChangeEvent('department_id')" v-model="scheduleForm.department_id" class="form-select" placeholder="Select" :class="{'is-invalid':clientError.includes('department_id')}" >
                    <option v-for="dprtmnt in Departments" :key="dprtmnt?.id" :value="dprtmnt?.id">{{ dprtmnt?.name }}</option>
                  </select>
                <span class="text-danger" v-if="formErrors && formErrors?.department_id && formErrors?.department_id != null">{{ formErrors?.department_id[0]  }}</span>

                </div>
              </div> -->
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Employee Name <span class="text-danger">*</span></label
                  >
                  <select @change="handleChangeEvent('employee_id')" :class="{'is-invalid':clientError.includes('employee_id')}" v-model="scheduleForm.employee_id" class="form-select" placeholder="Select">
                    <option v-for="emp in Employees" :key="emp?.id" :value="emp?.id">{{ emp?.first_name }} {{ emp?.last_name }}</option>
                  </select>
                <span class="text-danger" v-if="formErrors && formErrors?.employee_id && formErrors?.employee_id != null">{{ formErrors?.employee_id[0]  }}</span>

                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="scheduleForm.date"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                      :class="{'is-invalid':clientError.includes('date')}"
                      @change="handleChangeEvent('date')"
                    />
                  </div>
                  <span class="text-danger" v-if="formErrors && formErrors?.date && formErrors?.date != null">{{ formErrors?.date[0]  }}</span>

                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Select Shifts <span class="text-danger">*</span></label
                  >
                  <span class="text-danger" v-if="formErrors && formErrors?.shift_id && formErrors?.shift_id != null">{{ ' '+formErrors?.shift_id[0]  }}</span>

                </div>
              </div>
            </div>
              <div class="row shift_radio">
                <div class="col-md-3 col-lg-3 col-sm-3 p-0" v-for="shift in Shifts" :key="shift?.shift_id">
                  <label>
                    <input @change="checkSchedulereccurent(shift)" ref="shift_change_event"  type="radio" name="shift_id" v-model="scheduleForm.shift_id" class="card-input-element" :value="shift?.shift_id" />

                      <div class="card card-default card-input">
                        <div class="card-header">{{ shift?.shift_name }}</div>
                        <div class="card-body">
                          <span class="username-info m-b-10">{{ convertUTCToLocal(shift?.start_time) }} - {{ convertUTCToLocal(shift?.end_time) }} ({{ (shift?.start_time && shift?.end_time) ? fetchTotalHours(shift?.start_time,shift?.end_time) : ''}})</span>
                        </div>
                      </div>
                  </label>
                </div>
              </div>
             <div class="row">
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Accept Extra Hours </label>
                  <div class="form-switch">
                    <input
                     @change="handleChangeEvent('accept_extra_hours')"
                      v-model="scheduleForm.accept_extra_hours"
                      type="checkbox"
                      class="form-check-input"
                      id="customSwitch1"
                      value="true"
                      :checked="scheduleForm.accept_extra_hours"
                    />
                    <span class="text-danger" v-if="formErrors && formErrors?.accept_extra_hours && formErrors?.accept_extra_hours != null">{{ formErrors?.accept_extra_hours[0]  }}</span>

                    <label class="form-check-label" for="customSwitch1"></label>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Publish </label>
                  <div class="form-switch">
                    <input
                      @change="handleChangeEvent('active')"
                      v-model="scheduleForm.active"
                      type="checkbox"
                      class="form-check-input"
                      id="customSwitch2"
                      value="true"
                      :checked="scheduleForm.active"
                    />
                    <span class="text-danger" v-if="formErrors && formErrors?.active && formErrors?.active != null">{{ formErrors?.active[0]  }}</span>

                    <label class="form-check-label" for="customSwitch2"></label>
                  </div>
                </div>
              </div> 

              <div class="col-sm-12" v-if="showReccuringMessage">
                <div class="input-block mb-3">
                  <label class="col-form-label"> {{ reccuringMessage }}  </label>
                  <div class="form-switch">
                    <input
                      v-model="scheduleForm.isReccuring"
                      type="checkbox"
                      class="form-check-input"
                      id="customSwitch3"
                      value=true
                      :checked="scheduleForm.isReccuring"
                    />
                    <span class="text-danger" v-if="formErrors && formErrors?.active && formErrors?.active != null">{{ formErrors?.isReccuring[0]  }}</span>

                    <label class="form-check-label" for="customSwitch3"></label>
                  </div>
                </div>
              </div>

            </div>

            <div class="submit-section">
              <button class="btn btn-primary submit-btn" :disable="disableButton" @click="onManageShift()">Submit</button>
              <button v-if="scheduleForm?.schedule_id != null && hasDeletePermission" class="btn btn-danger submit-btn" :disable="disableButton" data-bs-toggle="modal" data-bs-target="#delete_schedule_modal">
                <i class="fa-regular fa-trash-can m-r-5"></i> Delete 
              </button>
            </div>
          
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Schedule Modal -->
  <!-- Delete Schedule Modal -->
  <div class="modal custom-modal fade" id="delete_schedule_modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Schedule</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="onDeleteSchedule"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  ref="delete_schedule_modal_close"
                  class="btn btn-primary cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete Schedule Modal -->
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import { notification } from "ant-design-vue";
import dayjs from 'dayjs';
import axios from 'axios';
import moment from 'moment';
export default {
  emits:['reload-data','add-schedule'],
  props:{
    deleteId:[Number,String],
    closeModal:[Boolean],
    editSchedule:[Object,null],
    addEmployeeId:[Object,null]
  },
  data() {
    return {
      reloadShiftDetail:true,
      scheduleForm:{
        // department_id:null,
        employee_id:null,
        date:null,
        shift_id:null,
        // min_start_time:null,
        // start_time:null,
        // max_start_time:null,
        // min_end_time:null,
        // end_time:null,
        // max_end_time:null,
        // break_time:null,
        accept_extra_hours:false,
        active:false,
        isReccuring:false
      },
      showReccuringMessage:false,
      reccuringMessage:'',
      Shift:[],
      value: null,
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      value6: null,
      value17: null,
      value18: null,
      value19: null,
      value20: null,
      value21: null,
      value22: null,
      value23: null,
      startdate: currentDate,
      dateFormat: 'MM-dd-yyyy',
      startdateone: currentDateOne,
      Shifts:[],
      RepeatOne: ["1", "2", "3", "4", "5", "6"],
      DepartmentOne: [
        "Select",
        "Development",
        "Finance",
        "Finance and Management",
        "Hr & Finance",
        "ITech",
      ],
      EmployeeOne: [
        "Select",
        "Richard Miles",
        "John Smith",
        "Mike Litorus",
        "Wilmer Deluna",
      ],
      ShiftsOne: [
        "Select",
        "10'o clock Shift",
        "10:30 shift",
        "Daily Shift",
        "New Shift",
      ],
      Repeat: ["1", "2", "3", "4", "5", "6"],
      Department: [
        "Select",
        "Development",
        "Finance",
        "Finance and Management",
        "Hr & Finance",
        "ITech",
      ],
      Employee: [
        "Select",
        "Richard Miles",
        "John Smith",
        "Mike Litorus",
        "Wilmer Deluna",
      ],
      disableButton:false,
      formErrors:[],
      clientError:[],
      hasDeletePermission:null,
      Employees:null
    };
  },
  methods:{
    ...mapActions(['fetchAllDepartments','fetchAllEmployee','getAllShiftList','manageSchedule']),
    checkSchedulereccurent(shift = null)
    {

      this.reccuringMessage = '';
      this.showReccuringMessage = false;
      this.scheduleForm.isReccuring = false;

      if(shift?.recurring_shifts && shift?.recurring_shifts === 1)
      {


        this.showReccuringMessage = true;
         
        this.reccuringMessage = this.createShiftMessage(shift)

      }
    },
    convertUTCToLocal(time) {
      if(time)
      {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        // Determine AM or PM
        const period = hours >= 12 ? 'PM' : 'AM';

        let m = minutes;

        // Convert hours to 12-hour format
        let hours12 = hours % 12;
        hours12 = hours12 === 0 ? 12 : hours12; // 0 should be treated as 12 in 12-hour format
        if(m < 10)
        {
          m = '0'+m;
        }

        if(hours12 < 10)
        {
          hours12 = '0'+hours12;
        }

        // Construct the formatted time string
        const formattedTime = `${hours12}:${m}`;

        // Return the formatted time with period
        return `${formattedTime} ${period}`;
      }
      return null;
      },
      fetchTotalHours(startTime,endTime)
      {
        if(startTime && endTime)
        {
          const startDate = new Date(`2000-01-01 ${startTime}`);
          const endDate = new Date(`2000-01-01 ${endTime}`);
          // Calculate the time difference in milliseconds
          const timeDifference = endDate - startDate;

          // Calculate hours and minutes
          const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
          const totalMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

          // Format the result
          const formattedResult = `${totalHours} hrs ${totalMinutes} min`;

          return formattedResult;
        }
      },
    handleChangeEvent(target)
    {
      this.clientError = this.clientError.filter(item => item !== target);
    },
    onDeleteSchedule()
    {
      this.disableButton = true;
      if(this?.scheduleForm?.schedule_id != null)
      {
        axios.delete('/schedule/'+this?.scheduleForm?.schedule_id).then( response =>
        {
            this.$refs.delete_schedule_modal_close.click();

            this.$emit('reload-data');

            notification.open({
              message: response?.data?.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

        }).catch(error => {
          var response = error.response;
          if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				    
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              
            this.$router.push({name: 'login'}).catch(error => {}) 
            
            }else{
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              
            }
        })  
      }
      this.disableButton = false;
    },
    getTmeFormate(dateTimeObject){
      var date = new Date(dateTimeObject?.$d)

      if(date && date != null)
      {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format time string
        const timeString = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        return timeString;
      }
      else
      {
        const timeString = null
        return timeString;
      }
    },
    async onManageShift()
    {
      this.clientError = []
      this.disableButton = true;
      var formData = {
        // department_id:null,
        employee_id:null,
        date:null,
        shift_id:null,
        // min_start_time:null,
        // start_time:null,
        // max_start_time:null,
        // min_end_time:null,
        // end_time:null,
        // max_end_time:null,
        // break_time:null,
        accept_extra_hours:false,
        active:false,
        isReccuring:false
      };

      this.showReccuringMessage = false

      formData = { ...this.scheduleForm };

      formData.date = null;

      if(formData?.isReccuring == true)
      {
        this.showReccuringMessage = true
      }

      // if(!this.scheduleForm.department_id == '')
      // {
      //   formData.department_id = this.scheduleForm.department_id;
      // }
      // else
      // {
      //   this.clientError.push('department_id')
      // }

      if(!this.scheduleForm.employee_id == '')
      {
        formData.employee_id = this.scheduleForm.employee_id;
      }
      else
      {
        this.clientError.push('employee_id')
      }

      if(this.scheduleForm.shift_id != '')
      {
        formData.shift_id = this.scheduleForm.shift_id;
      }
      else
      {
        this.clientError.push('shift_id')
      }

      // if(!(this.scheduleForm?.min_start_time == 'NaN:NaN:NaN' || !this.scheduleForm?.min_start_time?.$d || this.scheduleForm?.min_start_time == null))
      // {
      //   formData.min_start_time = this.getTmeFormate(this.scheduleForm?.min_start_time)
      // }
      // else
      // {
      //   formData.min_start_time = null;
      //   this.clientError.push('min_start_time')
      // }
      // if(!(this.scheduleForm?.start_time == 'NaN:NaN:NaN' || !this.scheduleForm?.start_time?.$d || this.scheduleForm?.start_time == null))
      // {
      //   formData.start_time = this.getTmeFormate(this.scheduleForm?.start_time)
      // }
      // else
      // {
      //   formData.start_time = null;
      //   this.clientError.push('start_time')
      // }
      // if(!(this.scheduleForm?.end_time == 'NaN:NaN:NaN' || !this.scheduleForm?.end_time?.$d || this.scheduleForm?.end_time == null))
      // {
      //   formData.end_time = this.getTmeFormate(this.scheduleForm?.end_time)
      // }
      // else
      // {
      //   formData.end_time = null;
      //   this.clientError.push('end_time')
      // }
      // if(!(this.scheduleForm?.max_start_time == 'NaN:NaN:NaN' || !this.scheduleForm?.max_start_time?.$d || this.scheduleForm?.max_start_time == null))
      // {
      //   formData.max_start_time = this.getTmeFormate(this.scheduleForm?.max_start_time)
      // }
      // else
      // {
      //   formData.max_start_time = null;
      //   this.clientError.push('max_start_time')
      // }
      // if(!(this.scheduleForm?.min_end_time == 'NaN:NaN:NaN' || !this.scheduleForm?.min_end_time?.$d || this.scheduleForm?.min_end_time == null))
      // {
      //   formData.min_end_time = this.getTmeFormate(this.scheduleForm?.min_end_time)
      // }
      // else
      // {
      //   formData.min_end_time = null;
      //   this.clientError.push('min_end_time')
      // }
      // if(!(this.scheduleForm?.max_end_time == 'NaN:NaN:NaN' || !this.scheduleForm?.max_end_time?.$d || this.scheduleForm?.max_end_time == null))
      // {
      //   formData.max_end_time = this.getTmeFormate(this.scheduleForm?.max_end_time)
      // }
      // else
      // {
      //   formData.max_end_time = null;
      //   this.clientError.push('max_end_time')
      // }

      
      if (this.scheduleForm?.date) {
        const endonDate =  new Date(this.scheduleForm?.date);
        if (!isNaN(endonDate.getTime())) {
          formData.date = endonDate.getFullYear()+'-'+(endonDate.getMonth() + 1)+'-'+endonDate.getDate();
        }
      }
      else
      {
        formData.date = null
        this.clientError.push('date')
      }

      // if(this.scheduleForm?.break_time != '' && this.scheduleForm?.break_time > 0)
      // {
      //   formData.break_time = this.scheduleForm?.break_time
      // }
      // else
      // {
      //   this.clientError.push('break_time')
      // }

      if(this.scheduleForm?.schedule_id != null)
      {
        formData.schedule_id = this.scheduleForm?.schedule_id;
        formData.id = this.scheduleForm?.schedule_id;
      }

      this.disableButton = false;

      if(!this.clientError?.length)
      {
        const response = await this.manageSchedule(formData);

        this.disableButton = false;

        if(response?.error && response?.error != null)
        {
          this.formErrors = response?.error;
        }
        else if(response?.status == 200)
        {
          this.$emit('reload-data');
          notification.open({
              message:  response?.data?.message || 'Successfully Schedule Added',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

            this.scheduleForm={
            department_id:null,
            employee_id:null,
            date:null,
            shift_id:null,
            min_start_time:null,
            start_time:null,
            max_start_time:null,
            min_end_time:null,
            end_time:null,
            max_end_time:null,
            break_time:null,
            accept_extra_hours:false,
            active:false,
            isReccuring:false
          }

          this.showReccuringMessage = false;

          this.$refs?.closeModalRef.click()
        }

      } 

      

    },
    clickCloseModel(){
      this.scheduleForm = {
        // department_id:null,
        employee_id:null,
        date:null,
        shift_id:null,
        // min_start_time:null,
        // start_time:null,
        // max_start_time:null,
        // min_end_time:null,
        // end_time:null,
        // max_end_time:null,
        // break_time:null,
        accept_extra_hours:false,
        active:false,
        isReccuring:false
      }

      this.showReccuringMessage = false;
    },
    createShiftMessage(shift = null)
    {
        var date = shift?.indefinite ? ' end of month' : moment(shift?.endon).format('dddd, DD MMMM YYYY');
        return  ' Save with Recurring Shift : '+shift?.weekdays+' (until '+date+')';
    }
  },
  async created(){
    await this.fetchAllDepartments();
    this.Employees = await this.fetchAllEmployee()
    this.Shifts = await this.getAllShiftList();

    this.$store.dispatch('userPermissions', { module: 'schedule', action: 'delete' }).then(response =>{
        this.hasDeletePermission=null;
        this.$nextTick(()=>{
          this.hasDeletePermission = response;
        })
      })
  },
  
  computed: {
    ...mapGetters(['getAllDepartments']), 
    Departments() {
      return this.getAllDepartments ? this.getAllDepartments : [];
    }
  },
  watch:{
    addEmployeeId(newValue)
    {
      this.scheduleForm.employee_id = newValue?.id;
      this.scheduleForm.date = new Date(newValue?.date);
      // this.scheduleForm.department_id = newValue?.department_id || null;
    },
    'scheduleForm.shift_id': function(newValue) {
      if(newValue && this.reloadShiftDetail)
      {
        // var token = window.localStorage.getItem("token");
        // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        // axios.get('shifts/detail/'+newValue).then(response=>{

        //   if(response?.data?.data?.record && response?.data?.data?.record != null)
        //   {
        //     this.scheduleForm.min_start_time = dayjs(response?.data?.data?.record?.min_start_time, 'HH:mm:ss');
        //     this.scheduleForm.max_end_time = dayjs(response?.data?.data?.record?.max_end_time, 'HH:mm:ss');
        //     this.scheduleForm.max_start_time = dayjs(response?.data?.data?.record?.max_start_time, 'HH:mm:ss');
        //     this.scheduleForm.min_end_time = dayjs(response?.data?.data?.record?.min_end_time, 'HH:mm:ss');
        //     this.scheduleForm.start_time = dayjs(response?.data?.data?.record?.start_time, 'HH:mm:ss');
        //     this.scheduleForm.end_time = dayjs(response?.data?.data?.record?.end_time, 'HH:mm:ss');
        //     this.scheduleForm.break_time = response?.data?.data?.record?.break_time || null;
        //     //this.scheduleForm.date = response?.data?.data?.record?.endon ? new Date(response?.data?.data?.record?.endon) : null
            
        //   }
          
        // })
      }

      this.reloadShiftDetail = true;
    },
    async editSchedule(newValue){
      if(newValue != null)
      {
        this.reloadShiftDetail = false;
        this.Shifts = await this.getAllShiftList(newValue?.shift_id || null);
        this.scheduleForm.department_id = newValue?.department_id || null
        this.scheduleForm.employee_id = newValue?.employee_id || null
        this.scheduleForm.date = newValue?.date && newValue?.date != null ? new Date(newValue?.date) : null;
        this.scheduleForm.min_start_time = dayjs(newValue?.min_start_time, 'HH:mm:ss');
        this.scheduleForm.max_end_time = dayjs(newValue?.max_end_time, 'HH:mm:ss');
        this.scheduleForm.max_start_time = dayjs(newValue?.max_start_time, 'HH:mm:ss');
        this.scheduleForm.min_end_time = dayjs(newValue?.min_end_time, 'HH:mm:ss');
        this.scheduleForm.start_time = dayjs(newValue?.start_time, 'HH:mm:ss');
        this.scheduleForm.end_time = dayjs(newValue?.end_time, 'HH:mm:ss');
        this.scheduleForm.break_time = newValue?.break_time || null
        this.scheduleForm.accept_extra_hours = newValue?.accept_extra_hours || false
        this.scheduleForm.active = newValue?.active || false
        this.scheduleForm.schedule_id = newValue?.schedule_id || null
        this.scheduleForm.shift_id = newValue?.shift_id || null
        this.scheduleForm.isReccuring = newValue?.is_reccuring == true ? true : false
        this.showReccuringMessage = true;

        this.reccuringMessage = this.createShiftMessage(newValue?.shift);
        
      }
      else
      {
        this.scheduleForm = {
          department_id:null,
          employee_id:null,
          date:null,
          shift_id:null,
          min_start_time:null,
          start_time:null,
          max_start_time:null,
          min_end_time:null,
          end_time:null,
          max_end_time:null,
          break_time:null,
          accept_extra_hours:false,
          active:false,
          schedule_id:null,
          isReccuring:null,
        }
        this.showReccuringMessage = false
      }
    }
  }
};
</script>
<style scoped>
.shift_radio label {
    width: 100%;
}

.shift_radio .card-input-element {
    display: none;
}

.shift_radio .card-input {
    margin: 10px;
    padding: 0px;
}

.shift_radio .card-input:hover {
    cursor: pointer;
}

.shift_radio .card-input-element:checked + .card-input {
     box-shadow: 0 0 1px 1px #2ecc71;
}

</style>
