<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card-group m-b-30">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <span class="d-block">Upcoming/Today Birthdays</span>
                <h3 class="mt-3">{{ upcomingBirthdays.count }}</h3>
                <p v-if="upcomingBirthdays.details.length > 0" class="mb-0">
                  {{ birthdayMessage }}
                </p>
                <p class="mb-0" v-else>No birthdays in the next 15 days</p>
              </div>
              <div>
                <img
                  src="@/assets/img/Birthday.png"
                  alt="icon"
                  style="height: 80px; width: 80px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <span class="d-block">Upcoming Holidays</span>
                <h3 class="mt-3">{{ upcomingHolidays.count }}</h3>
                <ul v-if="upcomingHolidays.details.length > 0" class="mb-0">
                  <li
                    v-for="holiday in upcomingHolidays.details"
                    :key="holiday.name"
                  >
                    {{ holiday.name }} - {{ holiday.holiday_date }}
                  </li>
                </ul>
                <p class="mb-0" v-else>No holidays upcoming</p>
              </div>
              <div>
                <img
                  src="@/assets/img/Holiday.png"
                  alt="icon"
                  style="height: 80px; width: 80px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">Announcements</span>
                <h3 class="mt-3 mb-0">{{ announcementsCount }}</h3>
              </div>
              <div>
                <img
                  src="@/assets/img/Annousement.png"
                  alt="icon"
                  style="height: 80px; width: 80px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      upcomingBirthdays: { count: 0, details: [] },
      upcomingHolidays: { count: 0, details: [] },
      announcementsCount: 0,
    };
  },
  computed: {
    birthdayMessage() {
      if (this.upcomingBirthdays.details.length === 0) return "";

      const capitalizeName = (name) => {
        return name
          .split(" ")
          .map(
            (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
          )
          .join(" ");
      };

      const [firstUser, ...others] = this.upcomingBirthdays.details;
      const firstUserName = capitalizeName(firstUser.name);
      const othersCount = others.length;
      return othersCount > 0
        ? `${firstUserName} and ${othersCount} more employees have birthdays`
        : `${firstUserName} has an upcoming birthday`;
    },
  },
  methods: {
    fetchDashboardData() {
      axios
        .get("/dashboard/count")
        .then((response) => {
          const data = response.data.data;

          this.upcomingBirthdays = data.upcoming_birthdays || {
            count: 0,
            details: [],
          };
          this.upcomingHolidays = data.upcoming_holidays || {
            count: 0,
            details: [],
          };
          this.announcementsCount = data.announcements || 0;
        })
        .catch((error) => {
          console.error("Error fetching dashboard data:", error);
          this.upcomingBirthdays = { count: 0, details: [] };
          this.upcomingHolidays = { count: 0, details: [] };
          this.announcementsCount = 0;
        });
    },
  },
  mounted() {
    this.fetchDashboardData();
  },
};
</script>
