<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <dashboardheader :title="title+' '+user?.first_name+' '+user?.last_name" :path="path" />
        <!-- /Page Header -->
		<div class="row">
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3" @click="this.$router.push('/projects');" style="cursor:pointer;">
            <div class="card dash-widget">
              <div class="card-body">
                <span class="dash-widget-icon"><i class="fa-solid fa-cubes"></i></span>
                <div class="dash-widget-info">
                  <h3> {{ dashboardData?.projects?.length || 0 }}</h3>
                  <span>Projects</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3" @click="this.$router.push('/clients');" style="cursor:pointer;">
            <div class="card dash-widget">
              <div class="card-body">
                <span class="dash-widget-icon"
                  ><i class="fa-solid fa-dollar-sign"></i
                ></span>
                <div class="dash-widget-info">
                  <h3>{{ dashboardData?.totals?.clients || 0 }}</h3>
                  <span>Clients</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3" @click="this.$router.push('/tasks');" style="cursor:pointer;">
            <div class="card dash-widget">
              <div class="card-body">
                <span class="dash-widget-icon"><i class="fa-regular fa-gem"></i></span>
                <div class="dash-widget-info">
                  <h3>{{ dashboardData?.totals?.tasks || 0 }}</h3>
                  <span>Tasks</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3" @click="this.$router.push('/employees');" style="cursor:pointer;">
            <div class="card dash-widget">
              <div class="card-body">
                <span class="dash-widget-icon"><i class="fa-solid fa-user"></i></span>
                <div class="dash-widget-info">
                  <h3>{{ dashboardData?.totals?.employees || 0 }}</h3>
                  <span>Employees</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <admin-charts :data="dashboardData"></admin-charts>

        <!-- Statistics Widget -->
        <admin-statistics :data="dashboardData"></admin-statistics>
        <!-- /Statistics Widget -->
        
        <div class="row">
          <div class="col-md-6 d-flex">
            <div class="card card-table flex-fill">
              <div class="card-header">
                <h3 class="card-title mb-0">Clients</h3>
              </div>
              <div class="card-body">
                <admin-clients></admin-clients>
              </div>
              <div class="card-footer">
                <router-link to="clients">View all clients</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex">
            <div class="card card-table flex-fill">
              <div class="card-header">
                <h3 class="card-title mb-0">Recent Projects</h3>
              </div>
              <div class="card-body">
                <admin-recent-project :project="dashboardData?.projects || []"></admin-recent-project>
              </div>
              <div class="card-footer">
                <router-link to="projects">View all projects</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { notification } from "ant-design-vue";
export default {
  data() {
    return {
      title: "Welcome",
      path: "Dashboard",
      dashboardData:null
    };
  },
  computed:{
    user()
    {
      let user = localStorage.getItem('user');
      if(user && user != null)
      {
        user = JSON.parse(user);
      }

      return user ? user : null
    },
    today()
    {
      const today = moment();
      return today.format('dddd, DD MMMM YYYY');
    },
  },
  methods:{
    fetchData(){
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.formContainer,
        canCancel: false
      });

      axios.get('/dashboard').then(response => {
        loader.hide();

        this.dashboardData = null
        this.$nextTick(()=>{
          this.dashboardData = response?.data?.data;
        })
        
      }).catch(error =>{
        loader.hide();
        if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
            
            this.$router.push({name: 'login'}).catch(error => {}) 
        }

        if(error.response)
        {
          var response = (error.response);
              
          notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
              background: process.env.VUE_APP_WARNING_COLOR,
              },
          });

        }
        else
        {
            
            notification.open({
                message: 'Server Error',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_WARNING_COLOR,
                },
            });
        }
      })
    },
    getDateFromat(date)
    {
      return moment(date).format("ddd DD MMM YYYY");
    },
    redirectTo(route)
    {
      this.$router.push({path: route}).catch(error => {}) 

    }

  },
  mounted(){

    const hasPermissionChange = this?.$route?.params?.hasPermissionChange;
    if(hasPermissionChange)
    {
      notification.open({
						message: 'Your permission has been change.',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
    }

    this.fetchData()
  }
};
</script>
