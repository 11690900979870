<template>
  <!-- Add Overtime Modal -->
  <div id="add_overtime" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ overtimeForm?.overtime_id ? 'Edit' : 'Add' }} Overtime</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeModalOvertime"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Select Employee <span class="text-danger">*</span></label
              >
              <select v-model="overtimeForm.employee_id" class="form-select form-select-lg" :class="{'is-invalid' : errorsFront.includes('employee_id')}">
                <option value=""> Select </option>
                <option :value="emp.id" v-for="emp in employees" :key="'emp_'+emp.id"> {{ emp.first_name }} {{ emp.last_name }}</option>
              </select>
              <span class="text-danger" v-if="errors && errors?.employee_id && errors?.employee_id != null">{{ errors?.employee_id[0] }}</span>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Overtime Date <span class="text-danger">*</span></label
              >
              <div class="cal-icon">
                <datepicker
                  :class="{'is-invalid' : errorsFront.includes('startdate')}"
                  v-model="startdate"
                  placeholder="Choose Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
                <span class="text-danger" v-if="errors && errors?.date && errors?.date != null">{{ errors?.date[0] }}</span>
              </div>

            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Overtime Type <span class="text-danger">*</span></label
              >
              <select v-model="overtimeForm.overtime_type" class="form-select form-select-lg">
                <option value="Manual">Manual</option>
                <option value="Automatic">Automatic</option>
              </select>
              <span class="text-danger" v-if="errors && errors?.hours && errors?.hours != null">{{ errors?.hours[0] }}</span>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Overtime Hours <span class="text-danger">*</span></label
              >
              <input :class="{'is-invalid' : errorsFront.includes('hours')}" class="form-control" type="number" v-model="overtimeForm.hours" min="0" max="0" />
              <span class="text-danger" v-if="errors && errors?.hours && errors?.hours != null">{{ errors?.hours[0] }}</span>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Description <span class="text-danger">*</span></label
              >
              <textarea :class="{'is-invalid' : errorsFront.includes('description')}"  rows="4" v-model="overtimeForm.description" class="form-control"></textarea>
              <span class="text-danger" v-if="errors && errors?.description && errors?.description != null">{{ errors?.description[0] }}</span>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click="manageOvertime" :disabled="disabledButton">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Overtime Modal -->

  <!-- Edit Overtime Modal -->
  <!-- <div id="edit_overtime" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Overtime</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Select Employee <span class="text-danger">*</span></label
              >
              <vue-select :options="Name" id="select-emp" placeholder="-" />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Overtime Date <span class="text-danger">*</span></label
              >
              <div class="cal-icon">
                <datepicker
                  v-model="startdate"
                  placeholder="Choose Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                />
              </div>
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Overtime Hours <span class="text-danger">*</span></label
              >
              <input v-model="" class="form-control" type="number" min="0" max="24" />
            </div>
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Description <span class="text-danger">*</span></label
              >
              <textarea rows="4" class="form-control"></textarea>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->
  <!-- /Edit Overtime Modal -->

  <!-- Delete Overtime Modal -->
  <div class="modal custom-modal fade" id="delete_overtime" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Overtime</h3>
            <p>Are you sure want to Cancel this?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="$emit('delete-record')"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                  ref="closeDeleteModal"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Overtime Modal -->
</template>

<script>
import axios from 'axios';
import { ref } from "vue";
const currentDate = ref(new Date());
import { mapActions } from 'vuex';
import { notification } from "ant-design-vue";
import moment from 'moment';

export default {
  emits:['reload-data','delete-record'],
  props:{
    updateOvertime: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    closeModal:{
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  data() {
    return {
      select2: null,
      Name: ["-", "John Doe", "Richard Miles", "John Smith"],
      startdate: currentDate,
      dateFormat: 'MM-dd-yyyy',
      employees:[],
      overtimeForm:{
        employee_id:'',
        date:null,
        hours:null,
        description:'',
        overtime_id:null,
        overtime_type:'Manual'
      },
      errorsFront:[],
      disabledButton:false,
      errors:[]
    };
  },
  watch:{
    closeModal(newVal)
    {
      if(newVal == true)
      {
        this.$refs.closeDeleteModal.click();
      }
    },
    updateOvertime(newVal)
    {
      if(newVal && newVal != null)
      {
        this.overtimeForm.employee_id = newVal?.employee_id || null;
        this.startdate = newVal?.date ? new Date(newVal?.date) : null;
        this.overtimeForm.hours = newVal?.hours || null;
        this.overtimeForm.description = newVal?.description || '';
        this.overtimeForm.overtime_id = newVal?.overtime_id || '';
        this.overtimeForm.overtime_type = newVal.overtime_type || '';
      }
    }
  },
  methods:{
    ...mapActions(['getDateFormate']),
    async fetchEmployeeList(){
      await axios.get('/employees/all').then((response)=>{
  
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        this.employees = response?.data?.data || [];

      }).catch(error =>{
        console.log('error in fetching employee',error);
      })
    },
    resetOvertimeForm()
    {
      this.overtimeForm={
        employee_id:'',
        date:null,
        hours:null,
        description:'',
        overtime_id:null
      }
    },
    manageOvertime(){
      this.errorsFront = []
      this.disabledButton = true
      this.errors = null

      if(!this.overtimeForm?.employee_id)
      {
        this.errorsFront.push('employee_id')
      }
      if(!this.startdate)
      {
        this.errorsFront.push('startdate')
      }
      if(!this.overtimeForm?.hours || this.overtimeForm?.hours <= 0)
      {
        this.errorsFront.push('hours')
      }
      if(!this.overtimeForm?.description )
      {
        this.errorsFront.push('description')
      }

      const endonDate =  new Date(this.startdate);

      if (!isNaN(endonDate.getTime())) {
        endonDate.setMonth(endonDate.getMonth() + 1);
        const year = endonDate.getFullYear();
        const month = endonDate.getMonth().toString().padStart(2, '0');
        const day = endonDate.getDate().toString().padStart(2, '0');
        this.overtimeForm.date = `${year}-${month}-${day}`;
      }
      else
      {
        this.errorsFront.push('startdate')
        this.overtimeForm.date = null;
        this.disabledButton = false
        return false;
      }

      if(!this.errorsFront.length)
      {
        var token = window.localStorage.getItem("token");
	
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if(this.overtimeForm?.overtime_id && this.overtimeForm?.overtime_id != null)
        {

          let date = new Date(this.overtimeForm.date)
          date.setDate(date.getDate() - 1)
          this.overtimeForm.date = moment(date).format('YYYY-MM-DD');
          
          axios.put('/overtime/'+this.overtimeForm?.overtime_id,this.overtimeForm).then(response => {
          this.disabledButton = false

          if(response.status == 200)
          {
            notification.open({
              message: response?.data?.message || 'Successful',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

            this.$emit('reload-data');

            this.$refs?.closeModalOvertime.click();

            this.resetOvertimeForm();

            
          }
          }).catch(error => {

          if(error.response){
      
            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          
            this.errors = response?.data?.error
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
          this.disabledButton = false
          })
        }
        else{

          let date = new Date(this.overtimeForm.date)
          date.setDate(date.getDate() - 1)
          this.overtimeForm.date = moment(date).format('YYYY-MM-DD');

          axios.post('/overtime',this.overtimeForm).then(response => {
          this.disabledButton = false

          if(response.status == 200)
          {
            notification.open({
              message: response?.data?.message || 'Successful',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

            this.$emit('reload-data');

            this.$refs?.closeModalOvertime.click();

            this.resetOvertimeForm();

          }
          }).catch(error => {

          if(error.response){
      
            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });

            this.errors = response?.data?.error
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
          this.disabledButton = false
          })

        }
        
      }
      else
      {
        this.disabledButton = false
      }
    }
  },
  created(){
    this.fetchEmployeeList();
  } 
};
</script>
